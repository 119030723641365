import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AesService} from '../services/AesService';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class ConvertInterceptor implements HttpInterceptor{

  constructor(private aesEncryptDecryptService: AesService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.responseType === 'json') {
      // If the expected response type is JSON then handle it here.
      // @ts-ignore
      return this.handleJsonResponse(req, next);
    } else {
      return next.handle(req);
    }
  }
  // tslint:disable-next-line:typedef
  private handleJsonResponse(httpRequest: HttpRequest<any>, next: HttpHandler) {
    // Override the responseType to disable the default JSON parsing.
    // httpRequest = httpRequest.clone({responseType: 'text'});
    // Handle the response using the custom parser.
    return next.handle(httpRequest).pipe(map(event => {
      // @ts-ignore
      if (event instanceof HttpResponse) {
        if (event?.body.data !== '')
        {
          const decrypted = this.aesEncryptDecryptService.decryptData(event.body.data);
          return event.clone({body: {status: event.body.status, message: event.body.message, data: decrypted}});
        }
        return event.clone({body: {status: event.body.status, message: event.body.message, data: {}}});
      }
      return event;
    }));
  }

}
