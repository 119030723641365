<div class="policy">
    <div class=WordSection1>

        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:16.0pt;line-height:107%;font-family:"Arial",sans-serif;
        color:red'>CHÍNH SÁCH BẢO MẬT THÔNG TIN NGƯỜI DÙNG</span></b></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>1.  Mục đích thu thập thông tin người dùng</span></p>

        <p class=MsoNormal style='text-indent:.5in'><span style='font-size:12.0pt;
        line-height:107%;font-family:"Arial",sans-serif'>Việc thu thập dữ liệu của
        khách hàng  để hỗ trợ, tư vấn thông tin về các dòng sản phẩm của chúng tôi đang
        cung cấp đến với khách hàng.</span></p>

        <p class=MsoNormal style='text-indent:.5in'><span style='font-size:12.0pt;
        line-height:107%;font-family:"Arial",sans-serif'>Khách hàng sẽ tự chịu trách nhiệm
        về bảo mật và lưu giữ các thông tin liên hệ và hộp thư điện tử của mình. Ngoài
        ra, khách hàng có trách nhiệm thông báo kịp thời cho chúng tôi  về những hành
        vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ thông tin của bên thứ
        ba để có biện pháp giải quyết phù hợp.</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>2.  Phạm vi sử dụng thông tin</span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Hỗ
        trợ khách hàng khi mua sản phẩm của chúng tôi.</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Giải
        đáp thắc mắc khách hàng.</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Cung
        cấp cho khách hàng thông tin mới về sản phẩm</span></p>

        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Thực
        hiện các bản khảo sát khách hàng.</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:Symbol'>·<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Thực
        hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch vụ của chúng
        tôi.</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>3. Thời gian lưu trữ thông tin</span></p>

        <p class=MsoNormal style='text-indent:.5in'><span style='font-size:12.0pt;
        line-height:107%;font-family:"Arial",sans-serif'>Dữ liệu của khách hàng sẽ được
        lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự khách hàng đăng nhập và thực hiện
        hủy bỏ. Còn lại trong mọi trường hợp thông tin khách hàng sẽ được bảo mật trên
        hệ thống máy chủ chúng tôi.</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>4. Những người hoặc tổ chức có thể được tiếp cận với thông
        tin  </span></p>

        <p class=MsoNormal style='text-indent:.25in'><span style='font-size:12.0pt;
        line-height:107%;font-family:"Arial",sans-serif'>Chúng tôi cam kết sẽ không
        chia sẻ thông tin của khách hàng cho bất kỳ một công ty nào khác theo chính
        sách của công ty chúng tôi. Chúng tôi có thể tiết lộ hoặc cung cấp thông tin của
        Quý khách trong các trường hợp thật sự cần thiết như sau:</span></p>

        <p class=MsoListParagraphCxSpFirst style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:"Sitka Subheading Semibold"'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Khi
        có yêu cầu của các cơ quan pháp luật.</span></p>

        <p class=MsoListParagraphCxSpLast style='margin-left:.75in;text-indent:-.25in'><span
        style='font-size:12.0pt;line-height:107%;font-family:"Sitka Subheading Semibold"'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Chia
        sẻ thông tin khách hàng với đối tác chạy quảng cáo như Google ví dụ như tiếp thị
        lại khách hàng dựa theo hành vi của khách hàng.</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>&nbsp;</span></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>5. Địa chỉ của đơn vị thu thập và quản lý thông tin:</span></p>

        <p class=MsoNormal><b><span style='font-size:14.0pt;line-height:107%;
        font-family:"Arial",sans-serif'>CÔNG TY TNHH BEBERIA VIỆT NAM</span></b></p>

        <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>Địa chỉ: Số 167 phố Đồng Cam, Thị Trấn Liên Quan, Huyện
        Thạch Thất, Thành phố Hà Nội, Việt Nam</span></p>
      <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>6. Cam kết bảo mật thông tin cá nhân của Khách hàng :</span></p>
      <p class=MsoNormal style='text-indent:.25in' ><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Th&ocirc;ng tin c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng tr&ecirc;n trang <a href="http://babyface.beberia.me/">https://babyface.beberia.me/</a> được cam kết bảo mật tuyệt đối theo Ch&iacute;nh s&aacute;ch bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n được đăng tải tr&ecirc;n trang <a href="https://babyface.beberia.me/">https://babyface.beberia.me/</a>. Việc thu thập v&agrave; sử dụng th&ocirc;ng tin của mỗi Kh&aacute;ch h&agrave;ng chỉ được thực hiện khi c&oacute; sự đồng &yacute; của Kh&aacute;ch h&agrave;ng trừ những trường hợp ph&aacute;p luật c&oacute; quy định kh&aacute;c v&agrave; quy định n&agrave;y.<br />
Kh&ocirc;ng sử dụng, kh&ocirc;ng chuyển giao, cung cấp hoặc tiết lộ cho b&ecirc;n thứ 3 về th&ocirc;ng tin c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng khi kh&ocirc;ng c&oacute; sự đồng &yacute; của Kh&aacute;ch h&agrave;ng ngoại trừ c&aacute;c trường hợp được quy định tại quy định n&agrave;y hoặc quy định của ph&aacute;p luật.<br />
Trong trường hợp m&aacute;y chủ lưu trữ th&ocirc;ng tin bị hacker tấn c&ocirc;ng dẫn đến mất m&aacute;t dữ liệu c&aacute; nh&acirc;n của Kh&aacute;ch h&agrave;ng, Ban quản trị c&oacute; tr&aacute;ch nhiệm th&ocirc;ng b&aacute;o v&agrave; l&agrave;m việc với cơ quan chức năng điều tra v&agrave; xử l&yacute; kịp thời, đồng thời th&ocirc;ng b&aacute;o cho Kh&aacute;ch h&agrave;ng được biết về vụ việc.</span></p>
      <p class=MsoNormal><span style='font-size:12.0pt;line-height:107%;font-family:
        "Arial",sans-serif'>7. Cơ chế tiếp nhận và giải quyết khiếu nại liên quan đến thông tin Khách hàng :</span></p>
      <p class=MsoNormal style='text-indent:.25in'><span style='font-size:12.0pt;line-height:107%;font-family:"Arial",sans-serif'>Khi ph&aacute;t hiện th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh bị sử dụng sai mục đ&iacute;ch hoặc phạm vi, Kh&aacute;ch h&agrave;ng gửi email khiếu nại đến email <a href="mailto:beberiavn@gmail.com">beberiavn@gmail.com</a> hoặc gọi điện thoại tới số <strong>0782 333 035</strong> để khiếu nại v&agrave; cung cấp chứng cứ li&ecirc;n quan tới vụ việc cho Ban quản trị. Ban quản trị cam kết sẽ phản hồi ngay lập tức hoặc muộn nhất l&agrave; trong v&ograve;ng 24 giờ l&agrave;m việc kể từ thời điểm nhận được khiếu nại.<br />
Trong trường hợp kh&ocirc;ng thỏa thuận được, cả 2 b&ecirc;n chấp nhận T&ograve;a &Aacute;n l&agrave; cơ quan giải quyết tranh chấp.</span></p>

    </div>
</div>
