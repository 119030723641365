import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BabyfaceService } from '../../api/babyface.service';
import { LocationService } from '../../api/location.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit {
  id = '';
  listItem = [];
  formattedTime = '';
  formattedPay = '';
  formattedCancel = '';
  code = '';
  name = 'Mr';
  base64Image: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private babyfaceService: BabyfaceService
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.id = params.id;
    });
  }

  ngOnInit(): void {
    this.getCart();
  }

  getCart() {
    this.babyfaceService.getDetail({ order_id: this.id }).subscribe((resp) => {
      if (resp.status === 200) {
        const time = resp.data.created;
        const pay = resp.data.pay_date;
        const cancel = resp.data.cancel_date;
        const a = new Date(time * 1000);
        const b = new Date(pay * 1000);
        const c = new Date(cancel * 1000);
        // const hours = date.getHours();
        // const minutes = '0' + date.getMinutes();
        // const seconds = '0' + date.getSeconds();
        const year = a.getFullYear();
        const week = [
          'Chủ Nhật',
          'Thứ Hai',
          'Thứ Ba',
          'Thứ Tư',
          'Thứ Năm',
          'Thứ Sáu',
          'Thứ Bảy',
        ];
        const month = a.getMonth() + 1;
        const date = a.getDate();
        const hour = a.getHours();
        const min = (a.getMinutes() < 10 ? '0' : '') + a.getMinutes();
        const thu = week[a.getDay()];
        const formattedDate = date + '/' + month + '/' + year;
        // tslint:disable-next-line:triple-equals
        if (time != 0) {
          this.formattedTime =
            hour +
            ':' +
            min +
            ', ' +
            thu +
            ' ' +
            date +
            '/' +
            month +
            '/' +
            year;
        }
        // tslint:disable-next-line:max-line-length triple-equals
        if (pay != 0) {
          // tslint:disable-next-line:max-line-length
          this.formattedPay =
            b.getHours() +
            ':' +
            ((b.getMinutes() < 10 ? '0' : '') + b.getMinutes()) +
            ', ' +
            week[b.getDay()] +
            ' ' +
            b.getDate() +
            '/' +
            (b.getMonth() + 1) +
            '/' +
            b.getFullYear();
        }
        // tslint:disable-next-line:max-line-length triple-equals
        if (cancel != 0) {
          // tslint:disable-next-line:max-line-length
          this.formattedCancel =
            c.getHours() +
            ':' +
            ((c.getMinutes() < 10 ? '0' : '') + c.getMinutes()) +
            ', ' +
            week[c.getDay()] +
            ' ' +
            c.getDate() +
            '/' +
            (c.getMonth() + 1) +
            '/' +
            c.getFullYear();
        }
        resp.data.created = formattedDate;
        resp.data.pay_date = this.formattedPay;
        this.code = resp.data.order_code;
        this.listItem = resp.data;
      }

      // tslint:disable-next-line:triple-equals
      if (resp.status != 200) {
        window.location.replace('/gio-hang');
      }
    });
  }

  // tslint:disable-next-line:typedef
  public copOrder() {
    navigator.clipboard.writeText(this.code);
  }
}
