<div class="babyface-login">
  <div class="row login-header">
    <div class="col-6 logo-login">
      <a href="/">
        <img src="assets/images/logo-white.png" alt="" />
      </a>
    </div>
    <div class="col-6 icon-close">
      <a href="javascript:void(0)" data-dismiss="modal" aria-label="Close">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="login-google-apple">
    <hr />
    <div class="text-center w-100 pt-3 text-login">
      <span class="register-login"> Đăng ký/Đăng nhập qua ứng dụng </span>
      <span class="name-web"> Beberia </span>
    </div>
    <div class="login-button d-flex">
      <div class="login-google d-flex">
        <a href="javascript:void(0);" id="customBtn">
          <img
            src="assets/images/googleid.png"
            data-onsuccess="onSignIn"
            #loginRef
            class="g-signin2"
            data-width="auto"
            data-height="44"
            data-longtitle="true"
            alt=""
          />
        </a>
      </div>
      <div class="login-apple d-flex">
        <a href="javascript:void(0)" onclick="return false">
          <img
            src="assets/images/appleid.png"
            id="appleid-signin"
            data-color="black"
            data-border="true"
            data-type="sign in"
            alt=""
          />
        </a>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
