<div class="baby-face-cart" style="min-height: 87vh">
  <div class="cart-title d-flex">
    <span
      ><a href="javascript:history.go(-1);" style="color: #000"
        ><i class="fa fa-angle-left"></i>Quản lý đơn hàng</a
      ></span
    >
  </div>
  <div class="cart-on">
    <div class="baby-face-introduce w-100">
      <div>
        <ul class="nav nav-tabs w-100 items" id="myTab" role="tablist">
          <li class="nav-item" (click)="scroll()">
            <a
              class="nav-link active"
              (click)="setStatus(6)"
              id="all-tab"
              data-toggle="tab"
              href="#allTab"
              role="tab"
              aria-controls="introduce"
              aria-selected="true"
              >Tất cả</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="setStatus(0)"
              id="paying-tab"
              data-toggle="tab"
              href="#payingTab"
              role="tab"
              aria-controls="paying-tab"
              aria-selected="false"
              >Chờ thanh toán</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="setStatus(2)"
              id="analysis-tab"
              data-toggle="tab"
              href="#analysisTab"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >Đang phân tích</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="setStatus(3)"
              id="result-tab"
              data-toggle="tab"
              href="#resultTab"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >Xem kết quả</a
            >
          </li>
          <li class="nav-item" (click)="scrollLi()">
            <a
              class="nav-link"
              (click)="setStatus(5)"
              id="cancel-tab"
              data-toggle="tab"
              href="#cancelTab"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
              >Đã hủy</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="allTab"
          role="tabpanel"
          aria-labelledby="all-tab"
        >
          <div class="item-order" *ngFor="let item of listItem; index as i">
            <div class="code-order">
              <p class="code">
                <img
                  src="assets/images/fi_file-text.svg"
                  class="img-thumbnail"
                />
                <strong>{{ item.order_code }}</strong>
              </p>
              <p class="status-order" *ngIf="item.status == 0">
                Chờ thanh toán
              </p>
              <p class="status-order" *ngIf="item.status == 2">
                Đang phân tích
              </p>
              <p class="status-order" *ngIf="item.status == 3">
                <a href="/detail?id={{ item.id }}" style="color: #1adbac"
                  >Xem kết quả</a
                >
              </p>
              <p class="status-order" *ngIf="item.status == 5">Đã hủy</p>
            </div>
            <hr style="background: #f1f1f1; margin: 10px 0px" />
            <div class="detail-order">
              <div class="detail">
                <p *ngIf="item.images.length > 0">
                  <img [src]="item.image" class="img-thumbnail" width="80px" />
                </p>
                <p *ngIf="item.images.length == 0">
                  <img
                    src="{{ item.image }}"
                    class="img-thumbnail"
                    width="80px"
                  />
                </p>
                <p class="name-order">
                  <span>{{ item.item.name }}</span>
                  <span class="quantity-pr">x1</span>
                  <span class="price-pr">{{
                    item.pay_amount
                      .toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND"
                      })
                      .toString()
                  }}</span>
                </p>
              </div>
            </div>
            <hr style="background: #f1f1f1; margin: 10px 0px" />
            <div class="total-pr">
              <p class="quantity-pay">1 sản phẩm</p>
              <p class="total-pay">
                Tổng thanh toán:
                <span class="price-pr">{{
                  item.pay_amount
                    .toLocaleString("it-IT", {
                      style: "currency",
                      currency: "VND"
                    })
                    .toString()
                }}</span>
              </p>
            </div>
            <hr style="background: #f1f1f1; margin: 10px 0px" />
            <div class="link-detail">
              <a href="/detail?id={{ item.id }}" class="see-detail"
                >Xem chi tiết</a
              >
              <div *ngIf="item.status == 0">
                <a (click)="repayPayment(item.id)" class="paid"
                  >Thanh toán lại</a
                >
              </div>
              <div *ngIf="item.status == 2">
                <a class="paying">Đang chuẩn bị</a>
              </div>
              <div *ngIf="item.status == 3">
                <a href="/order" class="paid">Đặt lại ảnh</a>
              </div>
              <div *ngIf="item.status == 5">
                <a href="/order" class="paid">Đặt lại ảnh</a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="payingTab"
          role="tabpanel"
          aria-labelledby="paying-tab"
        >
          <div class="item-order" *ngFor="let item of listItem; index as i">
            <div *ngIf="item.status == 0">
              <div class="code-order">
                <p class="code">
                  <img
                    src="assets/images/fi_file-text.svg"
                    class="img-thumbnail"
                  />
                  <strong>{{ item.order_code }}</strong>
                </p>
                <p class="status-order" *ngIf="item.status == 0">
                  <a (click)="repayPayment(item.id)" class="paid"
                    >Thanh toán lại</a
                  >
                </p>
                <p class="status-order" *ngIf="item.status == 2">
                  Đang phân tích
                </p>
                <p class="status-order" *ngIf="item.status == 3">
                  <a href="/detail?id={{ item.id }}" style="color: #1adbac"
                    >Xem kết quả</a
                  >
                </p>
                <p class="status-order" *ngIf="item.status == 5">Đã hủy</p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="detail-order">
                <div class="detail">
                  <p *ngIf="item.images.length > 0">
                    <img
                      [src]="item.image"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p *ngIf="item.images.length == 0">
                    <img
                      src="{{ item.image }}"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p class="name-order">
                    <span>{{ item.item.name }}</span>
                    <span class="quantity-pr">x1</span>
                    <span class="price-pr">{{
                      item.pay_amount
                        .toLocaleString("it-IT", {
                          style: "currency",
                          currency: "VND"
                        })
                        .toString()
                    }}</span>
                  </p>
                </div>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="total-pr">
                <p class="quantity-pay">1 sản phẩm</p>
                <p class="total-pay">
                  Tổng thanh toán:
                  <span class="price-pr">{{
                    item.pay_amount
                      .toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND"
                      })
                      .toString()
                  }}</span>
                </p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="link-detail">
                <a href="/detail?id={{ item.id }}" class="see-detail"
                  >Xem chi tiết</a
                >
                <a (click)="repayPayment(item.id)" class="paid"
                  >Thanh toán lại</a
                >
              </div>
            </div>
          </div>
          <div class="cart-none d-none" *ngIf="status_0 == 0">
            <div class="cart-none-img">
              <img
                src="assets/images/not-cart.png"
                alt=""
                height="136px"
                width="162px"
              />
              <span class="text-center">Bạn chưa có đơn hàng nào</span>
            </div>
            <div class="cart-none-btn">
              <a href="/order"
                ><button class="text-center text-white">
                  Đăng ký BabyFace
                </button></a
              >
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="analysisTab"
          role="tabpanel"
          aria-labelledby="analysis-tab"
        >
          <div class="item-order" *ngFor="let item of listItem; index as i">
            <div *ngIf="item.status == 2">
              <div class="code-order">
                <p class="code">
                  <img
                    src="assets/images/fi_file-text.svg"
                    class="img-thumbnail"
                  />
                  <strong>{{ item.order_code }}</strong>
                </p>
                <p class="status-order" *ngIf="item.status == 0">
                  Chờ thanh toán
                </p>
                <p class="status-order" *ngIf="item.status == 2">
                  Đang phân tích
                </p>
                <p class="status-order" *ngIf="item.status == 3">
                  <a href="/detail" style="color: #1adbac">Xem kết quả</a>
                </p>
                <p class="status-order" *ngIf="item.status == 5">Đã hủy</p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="detail-order">
                <div class="detail">
                  <p *ngIf="item.images.length > 0">
                    <img
                      [src]="item.image"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p *ngIf="item.images.length == 0">
                    <img
                      src="{{ item.image }}"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p class="name-order">
                    <span>{{ item.item.name }}</span>
                    <span class="quantity-pr">x1</span>
                    <span class="price-pr">{{
                      item.pay_amount
                        .toLocaleString("it-IT", {
                          style: "currency",
                          currency: "VND"
                        })
                        .toString()
                    }}</span>
                  </p>
                </div>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="total-pr">
                <p class="quantity-pay">1 sản phẩm</p>
                <p class="total-pay">
                  Tổng thanh toán:
                  <span class="price-pr">{{
                    item.pay_amount
                      .toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND"
                      })
                      .toString()
                  }}</span>
                </p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="link-detail">
                <a href="/detail?id={{ item.id }}" class="see-detail"
                  >Xem chi tiết</a
                >
                <a class="paying">Đang chuẩn bị</a>
              </div>
            </div>
          </div>
          <div class="cart-none d-none" *ngIf="status_2 == 0">
            <div class="cart-none-img">
              <img
                src="assets/images/not-cart.png"
                alt=""
                height="136px"
                width="162px"
              />
              <span class="text-center">Bạn chưa có đơn hàng nào</span>
            </div>
            <div class="cart-none-btn">
              <a href="/order"
                ><button class="text-center text-white">
                  Đăng ký BabyFace
                </button></a
              >
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="resultTab"
          role="tabpanel"
          aria-labelledby="result-tab"
        >
          <div class="item-order" *ngFor="let item of listItem; index as i">
            <div *ngIf="item.status == 3">
              <div class="code-order">
                <p class="code">
                  <img
                    src="assets/images/fi_file-text.svg"
                    class="img-thumbnail"
                  />
                  <strong>{{ item.order_code }}</strong>
                </p>
                <p class="status-order" *ngIf="item.status == 0">
                  Chờ thanh toán
                </p>
                <p class="status-order" *ngIf="item.status == 2">
                  Đang phân tích
                </p>
                <p class="status-order" *ngIf="item.status == 3">
                  <a href="/detail?id={{ item.id }}" style="color: #1adbac"
                    >Xem kết quả</a
                  >
                </p>
                <p class="status-order" *ngIf="item.status == 5">Đã hủy</p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="detail-order">
                <div class="detail">
                  <p *ngIf="item.images.length > 0">
                    <img
                      [src]="item.image"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p *ngIf="item.images.length == 0">
                    <img
                      src="{{ item.image }}"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p class="name-order">
                    <span>{{ item.item.name }}</span>
                    <span class="quantity-pr">x1</span>
                    <span class="price-pr">{{
                      item.pay_amount
                        .toLocaleString("it-IT", {
                          style: "currency",
                          currency: "VND"
                        })
                        .toString()
                    }}</span>
                  </p>
                </div>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="total-pr">
                <p class="quantity-pay">1 sản phẩm</p>
                <p class="total-pay">
                  Tổng thanh toán:
                  <span class="price-pr">{{
                    item.pay_amount
                      .toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND"
                      })
                      .toString()
                  }}</span>
                </p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="link-detail">
                <a href="#" class="see-detail"></a>
                <a href="/detail?id={{ item.id }}" class="paid">Xem kết quả</a>
              </div>
            </div>
          </div>
          <div class="cart-none d-none" *ngIf="status_3 == 0">
            <div class="cart-none-img">
              <img
                src="assets/images/not-cart.png"
                alt=""
                height="136px"
                width="162px"
              />
              <span class="text-center">Bạn chưa có đơn hàng nào</span>
            </div>
            <div class="cart-none-btn">
              <a href="/order"
                ><button class="text-center text-white">
                  Đăng ký BabyFace
                </button></a
              >
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="cancelTab"
          role="tabpanel"
          aria-labelledby="cancel-tab"
        >
          <div class="item-order" *ngFor="let item of listItem; index as i">
            <div *ngIf="item.status == 5">
              <div class="code-order">
                <p class="code">
                  <img
                    src="assets/images/fi_file-text.svg"
                    class="img-thumbnail"
                  />
                  <strong>{{ item.order_code }}</strong>
                </p>
                <p class="status-order" *ngIf="item.status == 0">
                  Chờ thanh toán
                </p>
                <p class="status-order" *ngIf="item.status == 2">
                  Đang phân tích
                </p>
                <p class="status-order" *ngIf="item.status == 3">
                  <a href="/detail?id={{ item.id }}" style="color: #1adbac"
                    >Xem kết quả</a
                  >
                </p>
                <p class="status-order" *ngIf="item.status == 5">Đã hủy</p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="detail-order">
                <div class="detail">
                  <p *ngIf="item.images.length > 0">
                    <img
                      [src]="item.image"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p *ngIf="item.images.length == 0">
                    <img
                      src="{{ item.image }}"
                      class="img-thumbnail"
                      width="80px"
                    />
                  </p>
                  <p class="name-order">
                    <span>{{ item.item.name }}</span>
                    <span class="quantity-pr">x1</span>
                    <span class="price-pr">{{
                      item.pay_amount
                        .toLocaleString("it-IT", {
                          style: "currency",
                          currency: "VND"
                        })
                        .toString()
                    }}</span>
                  </p>
                </div>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="total-pr">
                <p class="quantity-pay">1 sản phẩm</p>
                <p class="total-pay">
                  Tổng thanh toán:
                  <span class="price-pr">{{
                    item.pay_amount
                      .toLocaleString("it-IT", {
                        style: "currency",
                        currency: "VND"
                      })
                      .toString()
                  }}</span>
                </p>
              </div>
              <hr style="background: #f1f1f1; margin: 10px 0px" />
              <div class="link-detail">
                <a href="/detail?id={{ item.id }}" class="see-detail"
                  >Xem chi tiết</a
                >
                <a href="/order" class="paid">Đặt lại ảnh</a>
              </div>
            </div>
          </div>
          <div class="cart-none d-none" *ngIf="status_5 == 0">
            <div class="cart-none-img">
              <img
                src="assets/images/not-cart.png"
                alt=""
                height="136px"
                width="162px"
              />
              <span class="text-center">Bạn chưa có đơn hàng nào</span>
            </div>
            <div class="cart-none-btn">
              <a href="/order"
                ><button class="text-center text-white">
                  Đăng ký BabyFace
                </button></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-none" *ngIf="listItem.length == 0">
      <div class="cart-none-img">
        <img
          src="assets/images/not-cart.png"
          alt=""
          height="136px"
          width="162px"
        />
        <span class="text-center">Bạn chưa có đơn hàng nào</span>
      </div>
      <div class="cart-none-btn">
        <a href="/order"
          ><button class="text-center text-white">Đăng ký BabyFace</button></a
        >
      </div>
    </div>
  </div>
</div>
