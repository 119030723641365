import { BabyfaceService } from 'src/app/api/babyface.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Post } from 'src/app/Model/Post';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit {
  id: any;
  detail: Post;
  listPost: Post[] = [];
  rand: Post[] = [];
  slug: string;

  constructor(
    private babyfaceService: BabyfaceService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private meta: Meta,
    private title: Title,
    private a: ChangeDetectorRef
  ) {
    this.slug = this.activatedRoute.snapshot.params['slug'];
    this.id = this.slug.slice(this.slug.lastIndexOf('-') + 1, this.slug.length);
    this.getDetail(this.id);
    this.getPost();
  }
  ngOnInit(): void {}
  back(): void {
    this.location.back();
  }
  getDetail(id) {
    this.babyfaceService.postDetal(id).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.detail = resp.data;
        this.title.setTitle(resp.data.title);
        this.meta.updateTag({ name: 'description', content: resp.data.quote });
        this.meta.updateTag({
          property: 'og:url',
          content: window.location.href,
        });
        this.meta.updateTag({ property: 'og:title', content: resp.data.title });
        this.meta.updateTag({
          property: 'og:image',
          content: resp.data.url_image,
        });
        this.meta.updateTag({
          property: 'og:image:type',
          content: 'image/jpeg',
        });
        this.meta.updateTag({ property: 'og:image:width', content: '600' });
        this.meta.updateTag({ property: 'og:image:height', content: '315' });
        this.meta.updateTag({
          property: 'og:description',
          content: resp.data.quote,
        });
        this.meta.addTags([
          //{ name: 'description', content: resp.data.quote },
          { name: 'title', content: resp.data.title },
          { name: 'keyword', content: resp.data.title },
          // { property: 'og:url', content: window.location.href },
          // { property: 'og:title', content: resp.data.title },
          // { property: 'og:description', content: resp.data.quote },
          // { property: 'og:image', content: resp.data.url_image },
          // { property: 'og:image:type', content: 'image/jpeg' },
          // { property: 'og:image:width', content: '600' },
          // { property: 'og:image:height', content: '315' },
          // { property: 'og:image:alt', content: resp.data.title },
          {
            property: 'article:publisher',
            content: 'https://www.facebook.com/groups/babyfacevn',
          },
          { property: 'article:tag', content: 'babyface' },
          { property: 'article:tag', content: 'dự đoán gương mặt em bé' },
          { property: 'article:tag', content: 'beberia' },
          {
            property: 'article:published_time',
            content: this.getTime(resp.data.created),
          },
        ]);
        this.a.detectChanges();
      }
    });
  }
  getTime(e) {
    return new Date(e * 1000).toLocaleString();
  }
  getPost() {
    this.babyfaceService.getPost({ type: 5 }).subscribe((resp: any) => {
      if (resp.status === 200) {
        this.listPost = resp.data.diaries;
        this.random();
      }
    });
  }
  random() {
    let arr = [];
    let loop = 3;
    for (let i = 0; i < loop; i++) {
      let a = Math.floor(Math.random() * (14 - 1 + 1) + 1);
      if (arr.includes(a)) {
        loop++;
      } else {
        arr.push(this.listPost[a]);
      }
    }
    this.rand = arr;
  }
  getLink() {
    return (
      'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href
    );
  }
  convertToSlug(e, id) {
    let slug = e
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');
    return slug + '-' + id;
  }
}
