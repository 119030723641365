<div class="policy">
    <h4 class="text-center bg-red" style="    font-weight: bold;
    color: #ff0000;">
        THANH TOÁN & HOÀN TIỀN
    </h4>
    <ol>
        <li>
            <div>
                <b>Thanh toán bằng hình thức thanh toán trực tuyến thông qua Cổng thanh toán trực tuyến.</b>
                <p>Lưu ý:</p>
                <p>Để có thể đặt hàng thành công, quý khách cần phải điền đầy thông tin người mua bao gồm các thông tin: Họ và tên người mua, Số điện thoại người mua, email người mua. </p>
            </div>
        </li>
        <li>
            <div>
                <b>Chính sách hoàn tiền</b>
                <ul>
                    <li>
                        Chúng tôi sẽ hoàn tiền cho quý khách trong trường hợp ảnh siêu âm không phân tích được
                    </li>
                    <li>
                        Đối với những đơn hàng muốn dừng sử dụng và hoàn tiền, quý khách vui lòng bấm hủy đơn hàng trước hai ngày kể từ khi đặt đơn, sau hai ngày thì không thể hủy đơn hàng.
                    </li>
                </ul>
            </div>
        </li>
    </ol>
    <div style="padding-left: 20px;">
        <p>
            Mọi thông tin khách hàng cần hỗ trợ có thể liên hệ:
        </p>
        <p>
            Hotline: 0782333035
        </p>
        <p>
            Email: beberiavn@gmail.com
        </p>
        <p>
            Nhắn tin cho page
            <a href="https://www.facebook.com/beberia.mebe">https://www.facebook.com/beberia.mebe</a>
        </p>
    </div>
</div>