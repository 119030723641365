import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Item } from 'src/app/Model/Item';
import { BabyfaceService } from 'src/app/api/babyface.service';
import { Review } from 'src/app/Model/Review';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  datas:Item[]=[];
  listReview: Review[] = [];

  auth2: any;

  @ViewChild('loginRef', {static: true }) loginElement!: ElementRef;

  constructor(private babyfaceService:BabyfaceService) { }

  ngOnInit(): void {
    this.getItem();
    this.getReview();
    /**
     * Check token expiry
     */
     let token = localStorage.getItem('token');
     const item = JSON.parse(token);
     if (token != null){
       if (Math.floor(new Date().getTime() / 1000) > item.expiry){
         localStorage.clear();
       }
     }
  }

  /**
   * get item
   */
  getItem(){
    this.babyfaceService.getItem().subscribe((res:any)=>{
      this.datas = res.data.datas;
    });

  }

  /**
   * get review
   */
   getReview(){
    this.babyfaceService.getReview().subscribe((resp:any)=>{
      if (resp.status === 200){
        this.listReview = resp.data.datas;
      }
    })
  }

}
