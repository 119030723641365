import { Component, OnInit } from '@angular/core';
import { BabyfaceService } from 'src/app/api/babyface.service';
import { Item } from 'src/app/Model/Item';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit {
  datas: Item[] = [];

  constructor(private item: BabyfaceService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    //console.log(this.route.snapshot.params['id']);
  }
  getItem() {
    this.item.getItem().subscribe((res: any) => {
      this.datas = res.data.datas;
    });
  }
}
