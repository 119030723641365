<div class="babyface-info">
  <div class="row info-header">
    <div class="col-6 logo-info">
      <a href="/">
        <img src="assets/images/logo-white.png" alt="" />
      </a>
    </div>
    <div class="col-6 icon-close">
      <a href="javascript:void(0)" data-dismiss="modal" aria-label="Close">
        <i class="fa fa-times" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="babyface-infomation">
    <hr />
    <div class="babyface-info-name d-flex">
      <div class="babyfacce-info-img">
        <img src="{{ user.avt }}" height="48px" width="48px" alt="info" />
      </div>
      <div class="info-name">
        <span class="info-hello"> Xin chào, </span>
        <span class="info-name-display">
          {{ user.name }}
        </span>
      </div>
    </div>
  </div>
  <div class="babyface-collapse-info">
    <div *ngIf="isHome == 0" class="collapse-info">
      <a href="/order" class="collapse-info-baby-face">
        <img
          src="assets/images/icon-logo.jpg"
          alt="icon-logo"
          width="22px"
          height="18.62px"
        />
        <span> Dự đoán gương mặt em bé </span>
        <i class="fa fa-angle-right"></i>
      </a>
      <hr />
    </div>
    <div class="collapse-info">
      <a href="/shop" class="collapse-info-baby-face">
        <img
          src="assets/images/buy-2.png"
          alt="icon-logo"
          width="22px"
          height="18.62px"
        />
        <span> Beberia Shop </span>
        <i class="fa fa-angle-right"></i>
      </a>
      <hr />
    </div>
    <div class="collapse-info">
      <a href="/gio-hang" class="collapse-info-baby-face order">
        <img
          src="assets/images/order.png"
          alt="icon-logo"
          width="22px"
          height="22px"
        />
        <span> Theo dõi đơn hàng </span>
        <i class="fa fa-angle-right"></i>
      </a>
      <hr />
    </div>
    <div class="collapse-info">
      <a href="/cam-nang" class="collapse-info-baby-face">
        <img
          src="assets/images/fi_book.png"
          alt="icon-logo"
          width="22px"
          height="18.62px"
        />
        <span> Cẩm nang Beberia </span>
        <i class="fa fa-angle-right"></i>
      </a>
      <hr />
    </div>
    <div class="collapse-info">
      <a href="/kho-luu-tru-video" class="collapse-info-baby-face">
        <img
          src="assets/images/play-video.png"
          alt="icon-logo"
          width="22px"
          height="18.62px"
        />
        <span> Kho lưu trữ video siêu âm </span>
        <i class="fa fa-angle-right"></i>
      </a>
      <hr />
    </div>
    <div class="collapse-info">
      <a href="https://beberia.page.link/app" class="collapse-info-baby-face">
        <img
          src="assets/images/send.png"
          alt="icon-logo"
          width="24px"
          height="20.62px"
        />
        <span> Chia sẻ ứng dụng </span>
        <i class="fa fa-angle-right"></i>
      </a>
      <hr />
    </div>
    <div class="logout">
      <a href="/" class="collapse-logout-baby-face" (click)="logout()">
        <img
          src="assets/images/logout.png"
          alt="icon-logo"
          width="22px"
          height="18.62px"
        />
        <span> Đăng xuất </span>
      </a>
    </div>
  </div>
</div>
<app-footer> </app-footer>
