<app-header></app-header>
<div class="baby-face-cart" style="background: #F6F6F6">
  <div class="cart-title d-flex" style="background: #FFFFFF;margin-bottom: 15px">
    <span><a href="javascript:void(0);" style="color:#000;"><i class="fa fa-angle-left" ></i>Thông tin đơn hàng</a></span>
  </div>
  <div class="detail-order detail-success" style="background: #FFFFFF;">
    <div *ngIf="listItem['status'] == 3" style="padding: 16px">
      <div class="header-detail" >
      <span>
        <img src="assets/images/Frame.png" alt="Error">
        Mã đơn hàng: {{listItem['order_code']}}
      </span>
        <span style="color: #1ADBAC">
          Đã hoàn thành
        </span>
      </div>
      <div class="time">
        Ngày yêu cầu: {{listItem['created']}}
      </div>
      <div class="image-order">
        <img [src]="listItem['result'][1]['image']['storageUri']" alt="Error" width="100%" #img>
      </div>
      <div class="button-order">
        <a href="https://www.facebook.com/beberia.mebe">Gửi ý kiến</a>
        <a href="https://api.beberia.me/api/web/v2/babyface/download?order_id={{listItem['id']}}" type="submit" id="downloadImage">Tải xuống</a>
        <a href="https://www.facebook.com/beberia.mebe">Đánh giá</a>
      </div>
    </div>
    <div *ngIf="listItem['status'] == 0">
      <ul class="form-detail-cart">
        <li>
          <div class="header-detail" >
          <span>
            <img src="assets/images/Frame.png" alt="Error">
            Mã đơn hàng: <strong>{{listItem['order_code']}}</strong>
          </span>
            <span style="color: #0299FE;cursor: pointer;" (click)="copOrder()">
          Sao chép
          </span>
          </div>
          <div class="time">
            Ngày yêu cầu: {{listItem['created']}}
          </div>
        </li>
        <li>
          <hr style="float: left;width: 1px;height: 125px;background: #F1F1F1;margin-top: 40px;position: relative;left: 8px;z-index: 1;">
          <ul class="ul-theodoi">
            <li><img src="assets/images/icon-order.png" alt="Error" style="margin-right: 5px">
              Theo dõi đơn hàng</li>
            <li class="active-li">
              <div>
                <p>Chờ thanh toán</p>
                {{formattedTime}}
              </div>
            </li>
            <li>
              Đang chuẩn bị
            </li>
            <li>
              Đã hoàn thành
            </li>
            <li>Đã gửi</li>
          </ul>
        </li>
        <li class="infor-psersion">
          <ul>
            <li><img src="assets/images/fi_map-pin.png" alt="Error" style="margin-right: 5px"> Thông tin người nhận</li>
            <li>{{listItem['contactName']}}</li>
            <li>{{listItem['phone']}}</li>
            <li>{{listItem['contactEmail']}}</li>
          </ul>
        </li>
        <li class="infor-order">
          <p><img [src]="listItem['image']" alt="Error" width="54px" style="padding: 3px;border: 1px solid #F2F2F2;border-right: 2px;margin-right: 20px"></p>
          <p style="display: grid">
            <span style="font-size: 14px;font-weight: 500;color: #4D4D4D">{{listItem['item']['name']}}</span>
            <span>x1 <strong style="color:#0299FE;font-weight: 500">{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</strong></span>
          </p>
        </li>
        <li>
          <p style="font-size: 14px;color: #fd799d;margin-bottom: 5px"><img src="assets/images/fi_credit-card.png" alt="Error" style="margin-right: 5px"> Hình thức thanh toán</p>
          <p style="padding-left: 25px;font-size: 13px;margin-bottom: 0px">VNPay/ Thẻ ATM</p>
        </li>
        <li style="margin-bottom: 0px">
          <p style="display:flex;justify-content: space-between;font-size: 13px;color: #4d4d4d;margin-bottom: 5px">
            <span>Tổng tiền</span>
            <span>{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</span>
          </p>
          <hr>
          <p style="display:flex;justify-content: space-between;font-size: 16px;color: #4d4d4d;margin-bottom: 0px">
            <span>Tổng thanh toán</span>
            <span style="color:#00EEB5;">{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</span>
          </p>
        </li>
        <li class="huy-don">
          <a href="/cancel-order?id={{listItem['id']}}" style="color:#fd799d;"><button type="button" class="btn btn-primary btn-lg btn-block">Hủy đơn</button></a>
        </li>
      </ul>
    </div>
    <div *ngIf="listItem['status'] == 2" class="status-paid">
      <ul class="form-detail-cart">
        <li>
          <div class="header-detail" >
          <span>
            <img src="assets/images/Frame.png" alt="Error">
            Mã đơn hàng: <strong>{{listItem['order_code']}}</strong>
          </span>
            <span style="color: #0299FE;cursor: pointer;" (click)="copOrder()">
          Sao chép
          </span>
          </div>
          <div class="time">
            Ngày yêu cầu: {{listItem['created']}}
          </div>
        </li>
        <li>
          <hr style="float: left;width: 1px;height: 125px;background: #F1F1F1;margin-top: 40px;position: relative;left: 8px;z-index: 1;">
          <ul class="ul-theodoi">
            <li><img src="assets/images/icon-order.png" alt="Error" style="margin-right: 5px">
              Theo dõi đơn hàng</li>
            <li class="active-li">
              <div>
                <p>Chờ thanh toán</p>
                {{formattedTime}}
              </div>
            </li>
            <li class="li-paydate active-li" style="color:#000;">
              <div>
                <p style="color:#0299FE;margin-bottom: 5px">Đang chuẩn bị</p>
                {{formattedPay}}
              </div>
            </li>
            <li>
              Đã hoàn thành
            </li>
            <li>Đã gửi</li>
          </ul>
        </li>
        <li class="infor-psersion">
          <ul>
            <li><img src="assets/images/fi_map-pin.png" alt="Error" style="margin-right: 5px"> Thông tin người nhận</li>
            <li>{{listItem['contactName']}}</li>
            <li>{{listItem['phone']}}</li>
            <li>{{listItem['contactEmail']}}</li>
          </ul>
        </li>
        <li class="infor-order">
          <p><img [src]="listItem['image']" alt="Error" width="54px" style="padding: 3px;border: 1px solid #F2F2F2;border-right: 2px;margin-right: 20px"></p>
          <p style="display: grid">
            <span style="font-size: 14px;font-weight: 500;color: #4D4D4D">{{listItem['item']['name']}}</span>
            <span>x1 <strong style="color:#0299FE;font-weight: 500">{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</strong></span>
          </p>
        </li>
        <li>
          <p style="font-size: 14px;color: #fd799d;margin-bottom: 5px"><img src="assets/images/fi_credit-card.png" alt="Error" style="margin-right: 5px"> Hình thức thanh toán</p>
          <p style="padding-left: 25px;font-size: 13px;margin-bottom: 0px">VNPay/ Thẻ ATM</p>
        </li>
        <li style="margin-bottom: 0px">
          <p style="display:flex;justify-content: space-between;font-size: 13px;color: #4d4d4d;margin-bottom: 5px">
            <span>Tổng tiền</span>
            <span>{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</span>
          </p>
          <hr>
          <p style="display:flex;justify-content: space-between;font-size: 16px;color: #4d4d4d;margin-bottom: 0px">
            <span>Tổng thanh toán</span>
            <span style="color:#00EEB5;">{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</span>
          </p>
        </li>
        <li class="huy-don">
          <a href="/cancel-order?id={{listItem['id']}}" style="color:#fd799d;"><button type="button" class="btn btn-primary btn-lg btn-block">Hủy đơn</button></a>
        </li>
      </ul>
    </div>
    <div *ngIf="listItem['status'] == 5" class="status-paid">
      <ul class="form-detail-cart">
        <li>
          <div class="header-detail" >
          <span>
            <img src="assets/images/Frame.png" alt="Error">
            Mã đơn hàng: <strong>{{listItem['order_code']}}</strong>
          </span>
            <span style="color: #0299FE;cursor: pointer;" (click)="copOrder()">
          Sao chép
          </span>
          </div>
          <div class="time">
            Ngày yêu cầu: {{listItem['created']}}
          </div>
        </li>
        <li>
          <hr style="float: left;width: 1px;height: 125px;background: #F1F1F1;margin-top: 40px;position: relative;left: 8px;z-index: 1;">
          <ul class="ul-theodoi">
            <li><img src="assets/images/icon-order.png" alt="Error" style="margin-right: 5px">
              Theo dõi đơn hàng</li>
            <li class="active-li">
              <div>
                <p>Chờ thanh toán</p>
                {{formattedTime}}
              </div>
            </li>
            <li class="li-paydate active-li" style="color:#000;" *ngIf="formattedPay != ''">
              <div>
                <p style="color:#0299FE;margin-bottom: 5px">Đang chuẩn bị</p>
                {{formattedPay}}
              </div>
            </li>
            <li *ngIf="formattedPay == ''">
              Đang chuẩn bị
            </li>
            <li >
              Đã hoàn thành
            </li>
            <li class="li-paydate active-li" style="color:#000;">
              <div>
                <p style="color:#0299FE;margin-bottom: 5px">Đã hủy</p>
                {{formattedCancel}}
              </div>
            </li>
          </ul>
        </li>
        <li class="infor-psersion">
          <ul>
            <li><img src="assets/images/fi_map-pin.png" alt="Error" style="margin-right: 5px"> Thông tin người nhận</li>
            <li>{{listItem['contactName']}}</li>
            <li>{{listItem['phone']}}</li>
            <li>{{listItem['contactEmail']}}</li>
          </ul>
        </li>
        <li class="infor-order">
          <p><img [src]="listItem['image']" alt="Error" width="54px" style="padding: 3px;border: 1px solid #F2F2F2;border-right: 2px;margin-right: 20px"></p>
          <p style="display: grid">
            <span style="font-size: 14px;font-weight: 500;color: #4D4D4D">{{listItem['item']['name']}}</span>
            <span>x1 <strong style="color:#0299FE;font-weight: 500">{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</strong></span>
          </p>
        </li>
        <li>
          <p style="font-size: 14px;color: #fd799d;margin-bottom: 5px"><img src="assets/images/fi_credit-card.png" alt="Error" style="margin-right: 5px"> Hình thức thanh toán</p>
          <p style="padding-left: 25px;font-size: 13px;margin-bottom: 0px">VNPay/ Thẻ ATM</p>
        </li>
        <li style="margin-bottom: 0px">
          <p style="display:flex;justify-content: space-between;font-size: 13px;color: #4d4d4d;margin-bottom: 5px">
            <span>Tổng tiền</span>
            <span>{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</span>
          </p>
          <hr>
          <p style="display:flex;justify-content: space-between;font-size: 16px;color: #4d4d4d;margin-bottom: 0px">
            <span>Tổng thanh toán</span>
            <span style="color:#00EEB5;">{{listItem['pay_amount'].toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString()}}</span>
          </p>
        </li>
<!--        <li class="huy-don">-->
<!--          <button type="button" class="btn btn-primary btn-lg btn-block"><a href="/cancel-order?id={{listItem['id']}}" style="color:#fd799d;">Hủy đơn</a></button>-->
<!--        </li>-->
      </ul>
    </div>

  </div>
</div>
<app-contact></app-contact>
<app-footer></app-footer>
