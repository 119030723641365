<app-header></app-header>
<div class="cart-title d-flex">
  <span>
    <a href="javascript:history.go(-1);" style="color: #000">
      <i class="fa fa-angle-left"></i>
      Cẩm nang
    </a>
  </span>
</div>
<div class="cam-nang mx-3 mx-lg-3">
  <div class="banner text-center">
    <img
      class="w-100"
      src="http://mediadev.beberia.me/b3RoZXIvMi9kZXRhaWxzL2FXMWhaMlZmU0RKek1UWTJNemN5T1RJNE9DNXdibWM9"
      alt=""
    />
  </div>
  <div class="post-list">
    <div class="post-list-title pt-3 pb-2">
      <span class="fw-7">Bài viết nổi bật</span>
    </div>
    <a
      *ngFor="let item of listPost.slice(0, 5); let i = index"
      class="post-list-child row pb-md-4 pb-2 text-decoration-none"
      href="{{ '/chi-tiet/' + convertToSlug(item.title, item.id) }}"
      href="javascript:void(0)"
      style="color: #000000"
    >
      <div class="post-img col-3">
        <a [routerLink]="['/chi-tiet', convertToSlug(item.title, item.id)]">
          <img class="w-100" src="{{ item.media[0].link }}" alt="" />
        </a>
      </div>
      <div class="post-content px-0 col-9">
        <span class="content fw-7 fs-14">
          {{ item.title }}
        </span>
        <p class="post-date fw-4 fs-13">{{ getTime(item.created) }}</p>
      </div>
    </a>
    <div *ngIf="page > 1" class="post-see-more text-center">
      <a href="bai-viet">
        <button type="button" class="">Xem thêm</button>
      </a>
    </div>
  </div>
  <div class="post-category">
    <div class="post-list-title pt-3 pb-1">
      <span class="fw-7 fs-14">Cẩm nang theo danh mục</span>
    </div>
    <div class="category d-flex text-center mt-2">
      <div
        (click)="category(0)"
        [class.active]="isCategory == 0"
        class="category-mami w-100"
      >
        <img
          src="assets/images/danh-cho-me.png"
          class="px-2"
          alt="Dành cho mẹ"
          height="70%"
        />
        <span class="fw-7 fs-13">Dành cho mẹ</span>
      </div>
      <div
        (click)="category(1)"
        [class.active]="isCategory == 1"
        class="category-baby w-100 pt-1"
      >
        <img
          src="assets/images/danh-cho-be.png"
          class="px-2"
          alt="Dành cho bé"
          height="70%"
        />
        <span class="fw-7 fs-13">Dành cho bé</span>
      </div>
    </div>
    <div *ngIf="isCategory == 0" class="list-category row mb-3">
      <div class="col-6 text-center pt-3">
        <!-- <a href="#" class="text-decoration-none color-212529"> -->
        <img
          src="assets/images/chuan-bi-truoc-sinh.png"
          alt="Chuẩn bị trước sinh"
          width="100%"
        />
        <span class="fw-5 fs-14"> Chuẩn bị trước sinh </span>
        <!-- </a> -->
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/sau-khi-sinh.png"
          alt="Sau khi sinh"
          width="100%"
        />
        <span class="fw-5 fs-14"> Sau khi sinh </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/cong-thuc-nau-an.png"
          alt="Công thức nấu ăn"
          width="100%"
        />
        <span class="fw-5 fs-14"> Công thức nấu ăn </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/lam-dep.png"
          alt="Làm đẹp & thời trang"
          width="100%"
        />
        <span class="fw-5 fs-14"> Làm đẹp & thời trang </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/doi-song-vo-chong.png"
          alt="Đời sống vợ chồng"
          width="100%"
        />
        <span class="fw-5 fs-14"> Đời sống vợ chồng </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/danh-gia-dich-vu.png"
          alt="Đánh giá dịch vụ"
          width="100%"
        />
        <span class="fw-5 fs-14"> Đánh giá dịch vụ </span>
      </div>
    </div>
    <div *ngIf="isCategory == 1" class="list-category row mb-3">
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/banh-tat-an-toan.png"
          alt="Bệnh tật / An toàn"
          width="100%"
        />
        <span class="fw-5 fs-14"> Bệnh tật / An toàn </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/cong-thuc-an-dam.png"
          alt="Công thức ăn dặm"
          width="100%"
        />
        <span class="fw-5 fs-14"> Công thức ăn dặm </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/van-dong-giao-duc.png"
          alt="Vận động / Giáo dục"
          width="100%"
        />
        <span class="fw-5 fs-14"> Vận động / Giáo dục </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img src="assets/images/dinh-duong.png" alt="Dinh dưỡng" width="100%" />
        <span class="fw-5 fs-14"> Dinh dưỡng </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/truyen-tho-ca.png"
          alt="Truyện / Thơ ca"
          width="100%"
        />
        <span class="fw-5 fs-14">Truyện / Thơ ca </span>
      </div>
      <div class="col-6 text-center pt-3">
        <img
          src="assets/images/danh-gia-dich-vu-be.png"
          alt=" Đánh giá dịch vụ"
          width="100%"
        />
        <span class="fw-5 fs-14"> Đánh giá dịch vụ </span>
      </div>
    </div>
  </div>
</div>
<app-contact></app-contact>
<app-footer></app-footer>
