<header>
  <div class="w-100 coupon">
    <img src="assets/images/header.png" style="width: 100%; height: 100%" />
  </div>
  <div class="menu">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="row w-100">
        <div class="col-6 d-flex">
          <a href="/">
            <img src="assets/images/logo.png" />
          </a>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <a
            href="javascript:void(0)"
            data-toggle="modal"
            (click)="checklogin()"
            data-target="#myModalCart"
            class="shopping-cart-a d-flex"
          >
            <img src="assets/images/buy.png" />
          </a>
          <a
            href="javascript:void(0)"
            data-toggle="modal"
            (click)="checklogin()"
            data-target="#myModal2"
            class="nav-bar d-flex"
          >
            <img src="assets/images/menu.png" />
          </a>
        </div>
      </div>
    </nav>
  </div>
  <div
    *ngIf="isLogin != null"
    class="modal right fade"
    id="myModal2"
    tabindex="-1"
    role="dialog"
    style="padding-right: 0px"
    aria-labelledby="myModalLabel2"
  >
    <div
      class="modal-dialog float-right-modal"
      role="document"
      style="background-color: white; margin: 0"
    >
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px">
          <app-info *ngIf="isLogin != null"></app-info>
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
  <div
    *ngIf="isLogin != null"
    class="modal right fade"
    id="myModalCart"
    tabindex="-1"
    role="dialog"
    style="padding-right: 0px"
    aria-labelledby="myModalCart"
  >
    <div
      class="modal-dialog float-right-modal"
      role="document"
      style="background-color: white; margin: 0"
    >
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px">
          <div
            class="app-cart"
            *ngIf="isLogin != null"
            style="min-height: 100vh"
          >
            <div class="row info-header">
              <div class="col-6 logo-info">
                <a href="/">
                  <img src="assets/images/logo-white.png" alt="" />
                </a>
              </div>
              <div class="col-6 icon-close">
                <a
                  href="javascript:void(0)"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <app-cart></app-cart>
            <app-footer></app-footer>
          </div>
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
  <div
    *ngIf="isLogin == null"
    class="modal right fade"
    id="myModalCartLogin"
    tabindex="-1"
    role="dialog"
    style="padding-right: 0px"
  >
    <div
      class="modal-dialog float-right-modal"
      role="document"
      style="background-color: white; margin: 0"
    >
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px">
          <app-login></app-login>
        </div>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
</header>
