import { Post } from 'src/app/Model/Post';
import { BabyfaceService } from 'src/app/api/babyface.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.css'],
})
export class ChildComponent implements OnInit {
  id: number;
  listPost: Post[] = [];
  page: 1;
  list = [];
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private babyfaceService: BabyfaceService,
    private cdr: ChangeDetectorRef
  ) {
    this.route.queryParams.subscribe((params: Params) => {
      this.id = params.id;
    });
  }

  ngOnInit() {
    if (this.id) {
    } else {
      this.getPost();
    }
  }
  back(): void {
    this.location.back();
  }
  getPost() {
    this.babyfaceService
      .getPost({ type: 5, page: this.page })
      .subscribe((resp: any) => {
        if (resp.status === 200) {
          this.listPost = this.listPost.concat(resp.data.diaries);
          this.page = resp.data.next_page;
        }
      });
  }
  convertToSlug(e, id) {
    let slug = e
      .toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-');
    return slug + '-' + id;
  }
  getTime(e) {
    return new Date(e * 1000).toLocaleString();
  }
  more() {
    this.getPost();
    this.cdr.detectChanges();
  }
}
