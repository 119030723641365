import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BabyfaceService } from 'src/app/api/babyface.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

declare global {
  interface Window {
    AppleID: any;
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  auth2: any;
  formLogin: FormGroup;
  // tslint:disable-next-line:variable-name
  provider_id = '';

  @ViewChild('loginRef', { static: true }) loginElement!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private babyfaceService: BabyfaceService
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/']);
    }
    this.googleAuthSDK();
    this.formLogin = this.formBuilder.group({
      provider_id: ['', Validators.required],
    });
    this.loginApple();
    document.addEventListener('AppleIDSignInOnSuccess', (e) => {
      this.provider_id = this.getDecodedAccessToken(
        e['detail'].authorization.id_token
      ).sub;
      if (this.provider_id) {
        this.formLogin.controls.provider_id.setValue(this.provider_id);
        this.postLogin();
      } else {
        alert('Đăng nhập bằng Apple ID không thành công!');
        window.location.href = '/';
      }
    });
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
      alert('Đăng nhập bằng Apple ID không thành công!');
      window.location.href = '/';
    });
  }
  /**
   * get Apple ID
   */
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  loginApple(): void {
    window.AppleID.auth.init({
      clientId: 'babyface.beberia.me',
      scope: 'name email',
      redirectURI: 'https://babyface.beberia.me',
      state: 'origin:web',
      usePopup: true,
    });
  }
  postLogin(): void {
    if (this.formLogin.valid) {
      this.babyfaceService.postLogin(this.formLogin.value).subscribe((resp) => {
        if (resp.status === 200) {
          const token = {
            value: resp.data.token,
            expiry: resp.data.exp,
          };
          const user = {
            name: resp.data.user.display_name,
            avt: resp.data.user.avatar,
            idx: resp.data.user.id,
          };

          localStorage.setItem('token', JSON.stringify(token));
          localStorage.setItem('user', JSON.stringify(user));

          window.location.href = '/';
        } else if (resp.status === 404) {
          window.location.href = '/dang-ky';
          localStorage.setItem('idSocial', this.provider_id);
        }
      });
    } else {
      window.scroll(0, 0);
    }
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  // tslint:disable-next-line:typedef
  callLoginButton(e) {
    this.auth2.attachClickHandler(
      e,
      {},
      (googleAuthUser: any) => {
        const profile = googleAuthUser.getBasicProfile();
        this.provider_id = profile.getId();
        this.formLogin.controls.provider_id.setValue(this.provider_id);
        this.postLogin();
      },
      (error: any) => {
        alert(JSON.stringify(error, undefined, 2));
      }
    );
  }

  /**
   * Write code on Method
   *
   * @return response()
   */
  // tslint:disable-next-line:typedef
  googleAuthSDK() {
    (window as any).googleSDKLoaded = () => {
      (window as any).gapi.load('auth2', () => {
        this.auth2 = (window as any).gapi.auth2.getAuthInstance({
          client_id:
            '329647181129-6cc2cso24o0argglf96p1scqdg4sfbhk.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.callLoginButton(document.getElementById('customBtn'));
      });
    };

    // tslint:disable-next-line:only-arrow-functions typedef
    (function (d, s, id) {
      // tslint:disable-next-line:one-variable-per-declaration prefer-const
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement('script');
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }
}
