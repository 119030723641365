<div class="babyface-register">
  <div class="row register-header">
      <div class="col-md-12 ">
        <div >
          <a href="javascript:history.go(-1);">
            <i class="fa fa-angle-left" aria-hidden="true"></i>
          </a>
          <div>
            Đăng ký tài khoản
          </div>
        </div>
      </div>
  </div>
  <form class="form-register" #target [formGroup]="formRegister" (ngSubmit)="postRegiter()" novalidate>
    <div class="register-form-info">
      <div class="register-form-title">
        <h3>Thông tin cá nhân</h3>
      </div>
        <div class="form-group form-floating"
          [class.error-form]="res_error['display_name']"
          [class.error-form]="formRegister.controls.display_name.invalid && (formRegister.controls.display_name.dirty || formRegister.controls.display_name.touched)">
          <input type="text"
            class="form-control" formControlName="display_name"
            placeholder="6 ký tự trở lên, viết liền bao gồm chữ và số">
          <label> Tên người dùng* </label>
        </div>
        <div class="error-api" *ngIf="res_error['display_name'] || submited">
          <span class="text-danger" >
            {{res_error['display_name']}}
          </span>
        </div>
        <div class="error-api" *ngIf="submited && f.display_name.errors">
          <span class="text-danger" *ngIf="f.display_name.errors.minlength">
            6 ký tự trở lên, viết liền bao gồm chữ và số
          </span>
          <span class="text-danger" *ngIf="f.display_name.errors.required">
            Tên người dùng không được để trống
          </span>
        </div>
        <div class="form-group form-floating"
          [class.error-form]="(formRegister.controls.phone.invalid && (formRegister.controls.phone.dirty || formRegister.controls.phone.touched)) || res_error['phone']">
          <input type="text"
            class="form-control"
            formControlName="phone"
            placeholder="Số điện thoại của bạn">
          <label>Số điện thoại</label>
        </div>
        <div class="error-api" *ngIf="res_error['phone'] || submited">
          <span class="text-danger" >
            {{res_error['phone']}}
          </span>
        </div>
        <div class="error-api" *ngIf="f.phone.errors && submited">
          <span class="text-danger" *ngIf="f.phone.errors.required">
            Số điện thoại không được để trống
          </span>
        </div>
        <div class="form-group form-floating"
        [class.error-form]="formRegister.controls.city_code.invalid && (formRegister.controls.city_code.dirty || formRegister.controls.city_code.touched)">
          <select class="form-control bg-white payment-input" id="label-city" formControlName="city_code" required>
            <option value="">Chọn tỉnh/ thành phố</option>
            <option *ngFor="let city of listCity" [value]="city.location_code">{{city.location_name_vi}}</option>
          </select>
          <label> Tỉnh/Thành phố* </label>
        </div>
        <div class="error-api" *ngIf="submited && f.city_code.errors">
          <span class="text-danger" *ngIf="f.city_code.errors.required">
            Vui lòng chọn Tỉnh/Thành phố
          </span>
        </div>
    </div>
    <div class="register-info-mami-baby">
      <div class="rregister-info-mami-baby">
        <h3>Thông tin mẹ và bé</h3>
        <span>Độ tuổi của mẹ</span>
      </div>
      <div class="button-age">
        <span [class.range-age-checked]="age_range_checked == 1" (click)="set_age_range(1)" class="button-me"><a href="javascript:void(0);">18 - 24</a></span>
        <span [class.range-age-checked]="age_range_checked == 2" (click)="set_age_range(2)" class="button-me"><a href="javascript:void(0);">25 - 29</a></span>
        <span [class.range-age-checked]="age_range_checked == 3" (click)="set_age_range(3)"  class="button-me"><a href="javascript:void(0);">30 - 34</a></span>
        <span [class.range-age-checked]="age_range_checked == 4" (click)="set_age_range(4)"  ><a href="javascript:void(0);">35 - 40</a></span>
      </div>
      <div class="error-api-age" *ngIf="submited && f.age_range.errors">
        <span class="text-danger" *ngIf="f.age_range.errors.required">
          Vui lòng chọn độ tuổi của mẹ
        </span>
      </div>
      <div class="age-baby">
        <div class="form-group form-floating">
          <input type="date"
            class="form-control"
            formControlName="birthday_baby"
            placeholder="Nếu mẹ nuôi con nhỏ">
          <label>Ngày sinh của con*</label>
        </div>
        <div class="form-group form-floating">
          <input type="date"
            formControlName="birthday_preg"
            class="form-control"
            placeholder="Nếu mẹ đang mang thai">
          <label>Ngày dự sinh*</label>
        </div>
      </div>
      <div class="error-api-age" *ngIf="submited && !f.birthday_baby.value && !f.birthday_preg.value ">
        <span class="text-danger">
          Vui lòng chọn ngày sinh của con hoặc ngày dự sinh
        </span>
      </div>
      <div class="form-police d-flex">
        <div class="police-check">
          <input class="police-checkbox" type="checkbox" name="" [checked]="check_policy" (change)="check_policy = !check_policy" id="">
        </div>
        <div class="police-text d-flex">
          <span>Để tạo tài khoản, bạn phải đồng ý với <a href="/dieu-khoan-su-dung" target="_blank">điều kiện và điều khoản</a> của chúng tôi.</span>
        </div>
      </div>
      <div class="button-submit">
        <button [disabled]="check_policy == false" type="submit" (click)="scroll(target)" [class.error-button]="check_policy == false">
          Hoàn tất
        </button>
      </div>
    </div>
  </form>
</div>
