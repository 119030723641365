import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AesService} from '../services/AesService';

@Injectable()
export class NoopInterceptor implements HttpInterceptor{
  constructor(private aesEncryptDecryptService: AesService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const encrypted = this.aesEncryptDecryptService.encryptData(req.body);
    const requestData = req.clone({body: {data: encrypted}});
    return next.handle(requestData);
  }
}
