import { Component, OnInit,Input } from '@angular/core';
import { BabyfaceService } from 'src/app/api/babyface.service';


@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent implements OnInit {
  @Input() listReview = [];
  reviews=[];

  images = [
    {path: 'assets/images/review_2.png'},
    {path: 'assets/images/review_2.png'},
    {path: 'assets/images/review_2.png'},
  ];
  
  constructor(private item:BabyfaceService) { }

  ngOnInit(): void {
  }
  ngAfterContentChecked():void{
    this.reviews = this.listReview.map((reviews) =>{
      return reviews.image;
    })
  }
}
