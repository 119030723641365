<app-header></app-header>
<div id="cover-spin"></div>
<div class="baby-face-cart">
  <div class="cart-title d-flex">
    <span *ngIf="step===1"><a href="javascript:history.go(-1);" style="color:#000;"><i class="fa fa-angle-left" ></i>Đăng ký BabyFace</a></span>
    <span *ngIf="step===2"><a (click)="previousStep()"><i class="fa fa-angle-left" ></i>Đăng ký BabyFace</a></span>
  </div>

  <div class="container-fluid" style="padding: 0px">
    <div class="row justify-content-center" style="width: 100%;margin: auto">
      <div class="col-12 col-sm-9 col-md-7 col-lg-6 col-xl-12 text-center p-0">
        <div class="card px-0 pt-4 pb-0 ">
          <form [formGroup]="uploadForm" (submit)="createOrder()" style="background: #E5E5E5">
            <!-- progressbar -->
            <ul id="progressbar" style="background: #FFFFFF">
              <li id="package" [class.active]="step >= 1" *ngIf="step==1" ><strong>Tải ảnh</strong></li>
              <li id="package" [class.active]="step >= 1" *ngIf="step==2" (click)="previousStep()"><strong>Tải ảnh</strong></li>
              <li id="imageSelect" [class.active]="step >= 2" *ngIf="step==1" (click)="nextStep()"><strong style="color:lightgrey;">Thanh toán</strong></li>
              <li id="imageSelect" [class.active]="step >= 2" *ngIf="step==2" ><strong>Thanh toán</strong></li>
              <li id="payment" [class.active]="step >= 3"><strong style="color: lightgrey">Chờ kết quả</strong></li>
            </ul>

            <!--            <div style="background: #FFFFFF">-->
<!--              <img src="assets/images/step-bar.png" alt="Error">-->
<!--            </div>-->
            <fieldset class="p-3 push-image" *ngIf="step===1" >
              <div class="upload-image">
                <div class="form-card" style="background: #FFFFFF;padding: 16px;margin-bottom: 8px;border-radius: 8px">
                  <div class="row">
                    <div class="col-12" style="display:flex;justify-content: space-between;align-items: baseline">
                      <h2 class="fs-title">1. Tải lên hình ảnh</h2>
                      <button type="button" class="btn btn-primary modal-note" data-toggle="modal" data-target="#exampleModalCenter">
                        Xem thêm lưu ý
                      </button>
                    </div>
                  </div>
                  <label class="select-file p-3 text-center" for="fileChild" style="cursor: pointer; display: block; padding: 0px !important;">
                    <input type="file" name="pic" accept="image/*" style="display: none" id="fileChild" multiple
                           (change)="getImage($event, 1)">
                    <span *ngIf="listChild.length === 0">
                  <div style="width: 162px;height: 162px;background: #F1F1F1;border: 1px dashed #CECECE;border-radius: 4px;margin: auto;display:flex;justify-content: center;align-items: center">
                    <img src="assets/images/upload-default.png" style="width: 48px">
                  </div>
                  <p style="color: #5D5B5B; margin: 0;padding-top: 10px">Tối đa 5 ảnh siêu âm của bé</p>
                  <span style="color: #FF6452">(Từ 25 tuần tuổi)</span>
                </span>
                    <div class="list-img-select text-left" *ngIf="listChild.length > 0" style="display:grid; grid-template-columns: 101px 101px 101px;grid-template-rows: 101px 101px;">
                      <div class="img-show" *ngFor="let image of listChild; index as imageIndex">
                        <div class="img-close" (click)="removeImage($event, imageIndex, 1)">X</div>
                        <img [src]="image" class="img-thumbnail img-select">
                      </div>
                      <div class="img-show" *ngFor="let item of listImageDetail" style="display:flex;align-items: center;justify-content: center;">
                      <img src="assets/images/upload-default.png" class="img-thumbnail img-select img-detail">
                    </div>
                    </div>
                  </label>
                  <hr>
                  <div>
                    <p style="margin-bottom: 0px"><label class="gender-title">Giới tính (không bắt buộc)</label></p>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="gender" id="default-gender" value="none" checked>
                      <label class="form-check-label" for="gender1">
                        Chưa rõ
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="gender" id="gender1" value="option1">
                      <label class="form-check-label" for="gender1">
                        Bé trai
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <input class="form-check-input" type="radio" name="gender" id="gender2" value="option2">
                      <label class="form-check-label" for="gender2">
                        Bé gái
                      </label>
                    </div>
                  </div>
              </div>
               <div class="select-frames" style="background: #FFFFFF;padding: 16px;border-radius: 8px">
                 <h2 class="fs-title">2. Chọn khung ảnh</h2>
                 <div class="row" *ngIf="listFrame" style="display:grid;    grid-template-columns: calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5) calc(100%/5);">
<!--                   <carousel [cellWidth]="60"  [margin]="5" [height]="84" >-->
<!--                     <div class="col-4 col-sm text-center carousel-cell" style="max-width: 60px;padding: 0px" *ngFor="let frame of listFrame; index as frameIndex">-->
<!--                       <label [for]="'frame'+frameIndex" style="max-width: 60px;padding: 0px">-->
<!--                         <img [src]="frame.link_frame" class="img-fluid img-center" style="border: none; padding: 0">-->
<!--                       </label>-->
<!--                       <input type="radio" [value]="frame.name" [id]="'frame'+frameIndex" formControlName="frame"-->
<!--                              (change)="frameActive(frame.link_sample)">-->
<!--                     </div>-->
<!--                   </carousel>-->
                   <div class=" text-center carousel-cell"  *ngFor="let frame of listFrame; index as frameIndex">
                     <label [for]="'frame'+frameIndex" style="max-width: 60px;padding: 0px">
                       <img [src]="frame.link_frame" class="img-fluid img-center" style="border: none; padding: 0">
                     </label><br>
                     <input type="radio" [value]="frame.name" [id]="'frame'+frameIndex" formControlName="frame"
                            (change)="frameActive(frame.link_sample)">
                   </div>
                 </div>
                 <br>
                 <img [src]="frameSample" class="img-thumbnail" style="border: none; padding: 0; width: 80%">
                <p style="font-style: italic;font-weight: 300;color:#5D5B5B;font-size: 13px;padding-top: 15px;margin-bottom: 0px">Sản phẩm minh họa</p>
               </div>
              </div>
              <div class="button-ft">
                <a style="cursor: pointer" class="come-back" (click)="previousStep()">
                  Quay lại
<!--                  <img src="assets/images/previous_step.png" class="img-thumbnail mt-2" style="width: 40%; border: none">-->
                </a>
                <a style="cursor: pointer" class="btn-next" (click)="nextStep()" >
                  Tiếp theo
<!--                  <img src="assets/images/next_step.png" class="img-thumbnail mt-2" style="width: 40%; border: none">-->
                </a>
              </div>
            </fieldset>
            <fieldset class="p-3 push-image" *ngIf="step===2">
              <div style="padding: 16px 16px 0px 16px">
                <div class="form-card" style="background: #FFFFFF;border-radius: 8px;padding: 16px">
                  <label class="fieldlabels" style="font-weight: 700;font-size: 16px;line-height: 24px;">3. Thông tin cá nhân</label>
                  <div class="row p-3">
                    <div class="form-group w-100">
                  <span class="border-lable-flt">
                    <input type="text" class="form-control bg-white  payment-input" id="label-name"
                           formControlName="contactName">
                    <label for="label-name" style="color: #FD799D;background: #fff;">Họ và tên <strong style="color: #FF0046">*</strong></label>
                  </span>
                      <span *ngIf="uploadForm.controls.contactName.invalid "  class="text-danger">
                    <span *ngIf="uploadForm.controls.contactName.errors.required">
                      Vui lòng nhập họ tên
                    </span>
                  </span>
                    </div>
                    <div class="form-group w-100">
                  <span class="border-lable-flt">
                    <input type="text" class="form-control bg-white payment-input" id="label-phone"
                           formControlName="phone">
                    <label for="label-phone" style="color: #FD799D;background: #fff;">Số điện thoại <strong style="color: #FF0046">*</strong></label>
                  </span>
                      <span *ngIf="uploadForm.controls.phone.invalid "  class="text-danger">
                    <span *ngIf="uploadForm.controls.phone.errors.required">
                      Vui lòng nhập số điện thoại
                    </span>
                    <span *ngIf="uploadForm.controls.phone.errors?.pattern">
                      Không đúng định dạng số điện thoại
                    </span>
                  </span>
                    </div>
                    <div class="form-group w-100">
                  <span class="border-lable-flt">
                    <input type="email" class="form-control bg-white payment-input" id="label-email"
                           formControlName="contactEmail" email="true">
                    <label for="label-email" style="color: #FD799D;background: #fff;">Email <strong style="color: #FF0046">*</strong></label>
                  </span>
                      <span *ngIf="uploadForm.controls.contactEmail.invalid"  class="text-danger">
                    <span *ngIf="uploadForm.controls.contactEmail.errors.required">
                      Vui lòng nhập email
                    </span>
                    <span *ngIf="uploadForm.controls.contactEmail.errors.email">
                      Không đúng định dạng email
                    </span>
                  </span>
                    </div>
                  </div>
                </div>
                <div class="form-card" style="background: #FFFFFF;border-radius: 8px;padding: 16px;margin-top: 8px">
                  <label class="fieldlabels" style="font-weight: 700;font-size: 16px;line-height: 24px;">4. Tên hoặc nickname của bé yêu</label>
                  <div class="row p-3" style="padding-bottom: 0px">
                    <div class="form-group w-100" style="margin-bottom: 0px">
                  <span class="border-lable-flt">
                    <input type="text" class="form-control bg-white payment-input" id="nickname"
                           formControlName="nickname" name="nickname">
                  </span>
                      <span style="font-size: 14px;color: #5D5B5B;font-weight: 400">(Không bắt buộc, không quá 20 kí tự)</span>
                    </div>
                  </div>
                </div>
                <div class="payment-form form-card" style="background: #FFFFFF;border-radius: 8px;margin-top: 10px;padding: 16px">
                  <label class="fieldlabels" style="font-weight: 700;font-size: 16px;line-height: 24px;">5. Chọn phương thức thanh toán</label>
                  <div style="padding-left: 25px">
                    <input class="form-check-input" type="radio" name="vnpay" id="vnpay" value="vnpay" checked>
                    <span>
                  <img src="assets/images/vnpay.png" alt="Error" style="margin-right: 10px">
                  <label for="vnpay"> VNPAY/ Thẻ ATM</label>
                </span>
                  </div>
                </div>
                <div class="payment-form form-card" style="background: #FFFFFF;border-radius: 8px;margin-top: 10px;padding: 16px">
                  <label class="fieldlabels" style="font-weight: 700;font-size: 16px;line-height: 24px;">6. Giá trị đơn hàng</label>
                  <div>
                    <p class="provisional">
                      <span>Tạm tính</span>
                      <span>200.000đ</span>
                    </p>
                    <p class="total-money">
                      <span>Tổng số tiền</span>
                      <span class="money">200.000đ</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="button-ft">
                <a style="cursor: pointer" class="come-back" (click)="previousStep()">
                  Quay lại
                  <!--                  <img src="assets/images/previous_step.png" class="img-thumbnail mt-2" style="width: 40%; border: none">-->
                </a>
                <a style="cursor: pointer" class="btn-next" (click)="createOrder()">
                  Tiếp theo
                  <!--                  <img src="assets/images/next_step.png" class="img-thumbnail mt-2" style="width: 40%; border: none">-->
                </a>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Các trường hợp không nhận diện được gương mặt em bé: </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-nd">
            <p>
              <img src="assets/images/fi_x-circle.svg" alt="" width="16px" height="16px">
              Ảnh em bé chưa đủ 25 tuần, các đường nét chưa rõ ràng.
            </p>
            <p>
              <img src="assets/images/fi_x-circle.svg" alt="" width="16px" height="16px">
              Góc mặt em bé nằm nghiêng chỉ thấy được 1 bên mặt.
            </p>
            <p>
              <img src="assets/images/fi_x-circle.svg" alt="" width="16px" height="16px">
              Chất lượng ảnh thấp không nhận diện được đường nét trên khuôn mặt em bé.
            </p>
            <div class="img-note">
              <p>
                <img src="assets/images/img-note-1.png" alt="Error" width="50%" style="padding-right: 5px">
                <img src="assets/images/img-note-2.png" alt="Error" width="50%" style="padding-left: 5px">
              </p>
              <p>
                <img src="assets/images/img-note-3.png" alt="Error" width="50%" style="padding-right: 5px">
                <img src="assets/images/img-note-4.png" alt="Error" width="50%" style="padding-left: 5px">
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border: 0px ">
          <button type="button" class="btn btn-primary btn-lg btn-block" data-dismiss="modal" style="background: #FF6452;border-radius: 8px;border: 0px">Đã hiểu</button>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-primary btn-result" id="btn-payment-error" data-toggle="modal" data-target="#exampleModal">
    Đăng ký không thành công
  </button>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="top: 25% !important;">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="width: 85% !important;margin: auto !important;">
        <div class="modal-body">
          <div style="text-align: center">
            <img src="assets/images/error3.svg" alt="Error" style="margin-bottom: 10px">
          </div>
          <div style="text-align: center">
            <h3 style="font-size: 18px;color: #141F3B">Đăng ký không thành công</h3>
            <p style="font-size: 14px;color: #5D5B5B;margin-bottom: 0px">Đã có lỗi xảy ra trong quá trình đăng ký.<br> Vui lòng kiểm tra lại.</p>
          </div>
        </div>
        <div class="modal-footer" style="border: 0px">
          <button type="button" class="btn btn-primary btn-lg btn-block" style="background: #FF6452;color: #FFFFFF;border-radius: 8px">Kiểm tra lại</button>
        </div>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-primary btn-result" data-toggle="modal" data-target="#error-payment">
    Than toán không thành công
  </button>

  <!-- Modal -->
  <div class="modal fade" id="error-payment" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="width: 85% !important;margin: auto !important;">
        <div class="modal-body">
          <div style="text-align: center">
            <img src="assets/images/error2.svg" alt="Error" style="margin-bottom: 10px">
          </div>
          <div style="text-align: center">
            <h3 style="font-size: 18px;color: #141F3B">Thanh toán không thành công</h3>
            <p style="font-size: 14px;color: #5D5B5B;margin-bottom: 0px">Vui lòng kiểm tra và thanh toán lại đơn hàng</p>
          </div>
        </div>
        <div class="modal-footer" style="border: 0px">
          <button type="button" class="btn btn-primary btn-lg btn-block" style="background: #FFFFFF;color: #FF6452;border-radius: 8px;border: 1px solid #FF6452">Về trang chủ Babyface</button>
          <button type="button" class="btn btn-primary btn-lg btn-block" style="background: #FF6452;color: #FFFFFF;border-radius: 8px">Thanh toán lại</button>
        </div>
      </div>
    </div>
  </div>
</div>
