<app-header></app-header>
<div class="cart-title d-flex">
  <span>BabyFace - Dự đoán gương mặt em bé</span>
</div>
<div class="babay-face">
  <div
    class="baby-face-product carousel slide carousel-fade z-depth-1-half"
    id="carousel-example-1z"
    data-ride="carousel"
  >
    <div class="img-baby-face">
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <img
            class="d-block"
            src="assets/images/babyface.png"
            alt="First slide"
          />
        </div>
        <div class="carousel-item">
          <img
            class="d-block"
            src="assets/images/baby-face-1.png"
            alt="First slide"
          />
        </div>
        <div class="carousel-item">
          <img
            class="d-block"
            src="assets/images/baby-face-2.png"
            alt="First slide"
          />
        </div>
        <div class="carousel-item">
          <img
            class="d-block"
            src="assets/images/baby-face-3.png"
            alt="First slide"
          />
        </div>
        <div class="carousel-item">
          <img
            class="d-block"
            src="assets/images/baby-face-4.png"
            alt="First slide"
          />
        </div>
      </div>
    </div>
    <div class="img-list-baby-face d-flex">
      <div
        class="carousel-cell"
        [class.active]="dataSlide == 0"
        (click)="setDataSilde(0)"
        data-target="#carousel-example-1z"
        data-slide-to="0"
      >
        <img src="assets/images/babyface-thumbnail.png" />
      </div>
      <div
        class="carousel-cell"
        [class.active]="dataSlide == 1"
        (click)="setDataSilde(1)"
        data-target="#carousel-example-1z"
        data-slide-to="1"
      >
        <img src="assets/images/baby-face-1-thumbnail.png" />
      </div>
      <div
        class="carousel-cell"
        [class.active]="dataSlide == 2"
        (click)="setDataSilde(2)"
        data-target="#carousel-example-1z"
        data-slide-to="2"
      >
        <img src="assets/images/baby-face-2-thumbnail.png" />
      </div>
      <div
        class="carousel-cell"
        [class.active]="dataSlide == 3"
        (click)="setDataSilde(3)"
        data-target="#carousel-example-1z"
        data-slide-to="3"
      >
        <img src="assets/images/baby-face-3-thumbnail.png" />
      </div>
      <div
        class="carousel-cell"
        [class.active]="dataSlide == 4"
        (click)="setDataSilde(4)"
        data-target="#carousel-example-1z"
        data-slide-to="4"
      >
        <img src="assets/images/baby-face-4-thumbnail.png" />
      </div>
    </div>
    <div class="baby-face-title">
      <span class="d-none">BabyFace - Dự đoán gương mặt em bé</span>
    </div>
    <div class="baby-face-price d-flex">
      <div class="baby-price">
        <img src="assets/images/price.png" />
      </div>
      <div class="baby-face-button w-100">
        <a href="/order" class="float-right">
          <button>Đăng ký ngay</button>
        </a>
      </div>
    </div>
  </div>
  <div class="baby-face-introduce w-100 pt-3">
    <ul
      id="navbar-example3"
      class="nav nav-tabs w-100"
      id="myTab"
      role="tablist"
    >
      <li class="nav-item" [class.active]="scrollId == 0">
        <a
          href="javascript:void(0)"
          class="nav-link"
          (click)="scroll(target, 0)"
          >Giới thiệu</a
        >
      </li>
      <li class="nav-item" [class.active]="scrollId == 1">
        <a
          href="javascript:void(0)"
          class="nav-link"
          (click)="scroll(target1, 1)"
          >Đánh giá</a
        >
      </li>
      <li class="nav-item" [class.active]="scrollId == 2">
        <a
          href="javascript:void(0)"
          class="nav-link"
          (click)="scroll(target2, 2)"
          >Câu hỏi</a
        >
      </li>
    </ul>
    <div
      #target
      data-spy="scroll"
      data-target="#navbar-example3"
      data-offset="0"
    >
      <div
        class="tab-pane fade show active"
        id="introduce"
        role="tabpanel"
        aria-labelledby="introduce-tab"
      >
        <div
          class="baby-video p-3 d-flex align-items-center justify-content-center"
        >
          <video
            (click)="pause()"
            width="100%"
            src="assets/video/embe.mp4"
            #videoPlayer
          ></video>
          <button *ngIf="isPlay == 0" (click)="play()" class="play"></button>
          <!-- <button *ngIf="isPlay == 1" (click)="pause()" class="pause"></button> -->
        </div>
      </div>
    </div>
    <div *ngIf="scrollId == 1" style="height: 20px" #target1></div>
    <div class="pt-1" #target1 style="background-color: #FFF0F6">
      <app-review [listReview]="listReview"></app-review>
      <div class="review" style="padding-bottom: 1px">
        <div class="text-center px-3">
          <span class="font-weight-bolder"
            >Mức độ hải lòng của nhơn 5000 bố mẹ</span
          >
          <h4 style="font-weight: 900">ĐÃ TRẢI NGHIỆM DỊCH VỤ !</h4>
          <p>
            (Theo khảo sát mức độ hài lòng của những người đã sử dụng Babyface)
          </p>
        </div>
        <div class="box-star" style="background-color: #ffffff">
          <div class="star text-center pt-4">
            <i class="fa fa-star m-2 color-fd799d" aria-hidden="true"></i>
            <i class="fa fa-star m-2 color-fd799d" aria-hidden="true"></i>
            <i class="fa fa-star m-2 color-fd799d" aria-hidden="true"></i>
            <i class="fa fa-star m-2 color-fd799d" aria-hidden="true"></i>
            <i class="fa fa-star m-2 color-fd799d" aria-hidden="true"></i>
          </div>
          <div class="poin text-center">
            <span class="s-40 fw-9">4.9</span>
            <span class="color-fd799d fw-9 s-24">/ 5</span>
          </div>
        </div>
      </div>
    </div>
    <div class="babyface-said">
      <div class="line-decor-demo">
        <img src="assets/images/line-decor-progress.png" alt="" />
      </div>
      <div class="intro-demo-title px-2">
        <span class="fw-9 color-fd799d s-18">Lợi ích từ Babyface mang lại</span>
        <span class="fw-9 color-fd799d s-18">có thể bố mẹ chưa biết</span>
      </div>
      <div class="bg-ellipse">
        <div class="bg-ellipse-1 d-flex py-2">
          <img src="assets/images/icon-baby.png" alt="" />
          <div class="my-auto d-block">
            <span class="fw-9 s-13 color-4D4D4D"
              >Hỗ trợ quá trình thai giáo</span
            >
            <span class="s-13">
              Khi bạn cảm thấy hạnh phúc thì em bé cũng sẽ cảm thấy hạnh phúc !
            </span>
          </div>
        </div>
        <div class="bg-ellipse-2 d-flex py-2">
          <img src="assets/images/icon-home.png" alt="" />
          <div class="my-auto d-block">
            <span class="fw-9 s-13 color-4D4D4D"
              >Món quà ý nghĩa cho gia đình</span
            >
            <span class="s-13">
              Chia sẻ hạnh phúc, mang lại niềm vui cho ông bà. Đó cũng chính là
              món quà thể hiện sự hiếu thảo đối với 2 bên gia đình
            </span>
          </div>
        </div>
        <div class="bg-ellipse-1 d-flex py-2">
          <img src="assets/images/icon-daddy.png" alt="" />
          <div class="my-auto d-block">
            <span class="fw-9 s-13 color-4D4D4D"
              >Giúp cha và em bé gắn kết yêu thương
            </span>
            <span class="s-13">
              Người chồng chưa cảm nhận được rõ ràng việc sắp trở thành bố thì
              Babyface sẽ giúp người chồng đó trở thành người nghiện con
            </span>
          </div>
        </div>
        <div class="bg-ellipse-2 d-flex py-2">
          <img src="assets/images/icon-calender.png" alt="" />
          <div class="my-auto d-block">
            <span class="fw-9 s-13 color-4D4D4D"
              >Món đồ không thể thiếu vào tháng cuối thai kỳ</span
            >
            <span class="s-13">
              Có thể sử dụng các món đồ, hình ảnh Babyface để trang trí lưu lại
              những kỷ niệm đáng nhớ hơn trong hành trình mang thai
            </span>
          </div>
        </div>
        <div class="bg-ellipse-1 d-flex py-2">
          <img src="assets/images/icon-mama.png" alt="" />
          <div class="my-auto d-block">
            <span class="fw-9 s-13 color-4D4D4D"
              >Giảm nỗi sợ hãi về việc sinh nở và trầm cảm trước sau sinh
            </span>
            <span class="s-13">
              Khi ngắm ảnh em bé, mẹ sẽ thấy hạnh phúc hơn và nỗi sợ hãi, trầm
              cảm sẽ biến mất
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="babyface-progress">
      <div class="line-decor-demo">
        <img
          src="assets/images/line-decor-progress.png"
          alt=""
          class="progress-image"
        />
      </div>
      <div class="intro-demo-title text-center">
        <span class="intro-demo-title-2 fw-9 s-24">Công nghệ của Babyface</span>
        <span class="intro-demo-title-1 s-18 fw-7"
          >& Quá trình phân tích gương mặt em bé</span
        >
      </div>
      <div class="m-3 progress-4">
        <div>
          <div class="progress-title d-flex">
            <img class="m-auto" src="assets/images/progress-1.png" alt="" />
            <img class="line-hr" src="assets/images/line-hr.png" alt="" />
            <span class="s-14"
              >Công nghệ AI sẽ nhận diện hình ảnh siêu âm và tạo ra hình ảnh dự
              đoán gương mặt ban đầu</span
            >
          </div>
          <div class="progress-des pb-3 d-none">
            <img
              class="mx-auto d-block mt-3 progress-des-img"
              src="assets/images/anh-sieu-am-3d.png"
              alt=""
            />
            <div class="line-progress-des">
              <img src="assets/images/line-1.png" alt="" />
              <div class="line-progress-des-text">
                <span>
                  Khai thác những đặc điểm trên khuôn mặt trẻ từ bức ảnh siêu âm
                  như: <b>Mắt, mũi, miệng</b>
                </span>
              </div>
              <img src="assets/images/line.png" alt="" />
            </div>
            <img
              class="w-100 px-3"
              src="assets/images/progress-des-1.png"
              alt=""
            />
            <div class="line-progress-des">
              <img src="assets/images/line-1.png" alt="" />
              <img
                class="mx-auto d-block progress-des-img-2"
                src="assets/images/cong-nghe-AI.png"
                alt="Công nghệ AI của babyface"
              />
            </div>
          </div>
        </div>
        <div class="mx-3 py-4 ">
          <img src="assets/images/ai.png" width="100%" alt="" />
        </div>
      </div>
      <div class="progress-1">
        <div class="progress-title d-flex">
          <img class="m-auto" src="assets/images/progress-2.png" alt="" />
          <img class="line-hr" src="assets/images/line-hr.png" alt="" />
          <span class="s-14"
            >Các chuyên gia sẽ phân tích tiếp hình ảnh dự đoán lần 1, sau đó sử
            dụng AI để hoàn thiện hình ảnh</span
          >
        </div>
        <div class="progress-des prog-des-2">
          <img
            class="progress-des-1"
            src="assets/images/progress-des-3.png"
            alt=""
          />
        </div>
      </div>
      <div class="progress-1">
        <div class="progress-title d-flex">
          <img src="assets/images/progress-3.png" alt="" />
          <img class="line-hr" src="assets/images/line-hr.png" alt="" />
          <span class="s-14"
            >Người kiểm duyệt cuối cùng sẽ kiểm tra lại các tiêu chí của lần
            phân tích thứ 2</span
          >
        </div>
        <div class="progress-des prog-des-2">
          <img
            class="progress-des-1"
            src="assets/images/progress-des-4.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="babyface-technology w-100">
      <img src="assets/images/technology.png" alt="" width="100%" />
    </div>
    <div
      class="intro-demo"
      [style.background]="'url(assets/images/bg-intro-demo.png)'"
    >
      <div class="line-decor-demo">
        <img src="assets/images/line-decor-demo.png" alt="" />
      </div>
      <div class="intro-demo-title">
        <span class="intro-demo-title-1"
          >Ảnh dự đoán và ảnh em bé sau sinh</span
        >
        <span class="intro-demo-title-2">Có thực sự giống nhau?</span>
      </div>
      <div class="intro-demo-image d-none">
        <span class="intro-demo-img-title-logo">
          <img
            src="assets/images/logo.png"
            alt=""
            height="16px"
            width="107px"
          />
          <span>dự đoán</span>
        </span>
        <span class="intro-demo-img-title-logo intro-demo-img-title-logo-2">
          <span
            >Ảnh thực tế <br />
            sau sinh</span
          >
        </span>
      </div>
      <div class="intro-demo-image-detail pt-3">
        <img src="assets/images/babyface-demo-1.png" alt="" />
        <img
          class="image-intro d-none"
          src="assets/images/babyface-demo-2.png"
          alt=""
        />
        <img
          class="image-intro d-none"
          src="assets/images/babyface-demo-3.png"
          alt=""
        />
      </div>
      <div class="intro-microsoft">
        <img src="assets/images/microsoft.png" alt="" />
        <span>Mô hình máy học từ Microsoft</span>
      </div>
    </div>
    <div class="babyface-4D">
      <div class="baby-4d-title text-center mx-5">
        <span class="fw-7 s-16">
          Trung bình tỷ lệ giống trên 95% ! <br />
          Babyface dự đoán chính xác gương mặt em bé đáng yêu bằng công nghệ AI
          tân tiến nhất
        </span>
      </div>
      <div class="baby-4d-btn text-center mx-auto">
        <p class="bb-btn fw-7 s-16">SẮP RA MẮT !</p>
      </div>
      <div class="babyface-name text-center pt-3">
        <span class="fw-9 s-24 color-fd799d">BabyFace 4D</span>
        <p class="px-5 fw-7 s-18 mb-0 pt-2">
          Cuộc gặp gỡ em bé bằng video <br />
          chân thực, sống động nhất
        </p>
        <span class="fw-4 s-13" style="color: #8c8686">(Phiên bản Beta)</span>
      </div>
      <div
        class="babyface-4d-image pb-4"
        [style.background]="'url(assets/images/bb-4d-bg.png)'"
      >
        <div class="bb-gif pt-3">
          <img
            class="mx-auto d-block"
            src="assets/images/baby-2.gif"
            alt=""
            height="313"
            width="311"
          />
        </div>
        <img
          class="d-block pt-4 mx-auto"
          src="assets/images/babyface-nang-cap.png"
          alt=""
        />
      </div>
    </div>
    <div *ngIf="scrollId == 2" style="height: 38px" #target2></div>

    <div #target2 class="baby-face-des">
      <div class="pb-3">
        <span class="fw-7 s-18">Thắc mắc thường gặp</span>
      </div>
      <div class="des-1 d-flex">
        <img
          class="d-block px-2 pt-1"
          src="assets/images/heart.png"
          alt=""
          height="100%"
        />
        <span
          >Kết quả được trả sau 3~5 ngày làm việc (không bao gồm thứ 7, chủ
          nhật) trong mục “Theo dõi đơn hàng"</span
        >
      </div>
      <div class="des-1 des-2 d-flex">
        <img
          class="d-block px-2 pt-1"
          src="assets/images/heart.png"
          alt=""
          height="100%"
        />
        <span
          >Ảnh phân tích từ tuần 25 trở lên. Các trường hợp không phân tích được
          gương mặt bé.</span
        >
      </div>
      <div class="des-more">
        <a href="#" data-toggle="modal" data-target="#exampleModalCenter"
          >Xem thêm <i class="fa fa-arrow-right" aria-hidden="true"></i
        ></a>
      </div>
      <div class="des-1 des-2 d-flex">
        <img
          class="mx-auto d-block px-2 pt-1"
          src="assets/images/heart.png"
          alt=""
          height="100%"
        />
        <span
          >Huỷ dịch vụ đã thanh toán : Thời gian hoàn tiền từ 5 ~ 8 ngày làm
          việc tuỳ từng ngân hàng (không bao gồm thứ 7, chủ nhật)</span
        >
      </div>
    </div>
  </div>
  <app-contact></app-contact>
  <app-footer></app-footer>
  <div class="modal-show">
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style="padding-right: 0 !important"
    >
      <div
        class="modal-dialog modal-dialog float-right"
        role="document"
        style="margin: 0"
      >
        <div class="modal-content">
          <div class="modal-header">
            <div class="row info-header">
              <div class="col-6 logo-info">
                <a href="/">
                  <img src="assets/images/logo-white.png" alt="" />
                </a>
              </div>
              <div class="col-6 icon-close">
                <a
                  href="javascript:void(0)"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Các trường hợp không nhận diện được gương mặt em bé:
            </h5>
            <div class="modal-nd">
              <p>
                <img
                  src="assets/images/fi_x-circle.svg"
                  alt=""
                  width="16px"
                  height="16px"
                />
                Ảnh em bé chưa đủ 25 tuần, các đường nét chưa rõ ràng.
              </p>
              <p>
                <img
                  src="assets/images/fi_x-circle.svg"
                  alt=""
                  width="16px"
                  height="16px"
                />
                Góc mặt em bé nằm nghiêng chỉ thấy được 1 bên mặt.
              </p>
              <p>
                <img
                  src="assets/images/fi_x-circle.svg"
                  alt=""
                  width="16px"
                  height="16px"
                />
                Chất lượng ảnh thấp không nhận diện được đường nét trên khuôn
                mặt em bé.
              </p>
              <div class="img-note">
                <p>
                  <img
                    src="assets/images/baby-img-error.png"
                    alt="Error"
                    width="50%"
                    style="padding-right: 5px"
                  />
                  <img
                    src="assets/images/baby-img-error-1.png"
                    alt="Error"
                    width="50%"
                    style="padding-left: 5px"
                  />
                </p>
                <p>
                  <img
                    src="assets/images/baby-img-error-2.png"
                    alt="Error"
                    width="50%"
                    style="padding-right: 5px"
                  />
                  <img
                    src="assets/images/baby-img-error-3.png"
                    alt="Error"
                    width="50%"
                    style="padding-left: 5px"
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="modal-footer d-none" style="border: 0px">
            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              data-dismiss="modal"
              style="background: #fd799d; border-radius: 8px; border: 0px"
            >
              Đã hiểu
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
