import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BabyfaceService } from '../../api/babyface.service';
import { LocationService } from '../../api/location.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
})
export class CartComponent implements OnInit {
  listItem = [];
  price = '';
  // tslint:disable-next-line:variable-name
  status_0 = 0;
  // tslint:disable-next-line:variable-name
  status_2 = 0;
  // tslint:disable-next-line:variable-name
  status_3 = 0;
  // tslint:disable-next-line:variable-name
  status_5 = 0;
  status = 6;
  uploadForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private babyfaceService: BabyfaceService, private locationService: LocationService) { }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      status: 6,
      page: 1,
    });
    this.getCart();
  }
  // tslint:disable-next-line:typedef
  getCart() {
    this.babyfaceService.getCart(this.status).subscribe((resp) => {
      if (resp.status === 200) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < resp.data.datas.length; i++) {
          // tslint:disable-next-line:triple-equals
          if (resp.data.datas[i].status == 0) {
            this.status_0 += 1;
          }
          // tslint:disable-next-line:triple-equals
          if (resp.data.datas[i].status == 2) {
            this.status_2 += 1;
          }
          // tslint:disable-next-line:triple-equals
          if (resp.data.datas[i].status == 3) {
            this.status_3 += 1;
          }
          // tslint:disable-next-line:triple-equals
          if (resp.data.datas[i].status == 5) {
            this.status_5 += 1;
          }
        }
        this.listItem = resp.data.datas;
      }
    });
  }
  setStatus(status){
    this.status = status;
    this.getCart();
  }
  // tslint:disable-next-line:typedef
  repayPayment(id) {
    this.babyfaceService.payment({ order_id: id }).subscribe((resp) => {
      // tslint:disable-next-line:triple-equals
      if (resp.status == 200) {
        window.location.replace(resp.data.payment_url);
      } else {
        alert('Tạo lại đơn không thành công!');
      }
    });
  }

  // tslint:disable-next-line:typedef
  scroll() {
    // const element = document.getElementById('myTab');
    // const a = element.scrollLeft;
    const rightBtn = document.querySelector('ul#myTab');
    rightBtn.scrollLeft -= 200;
    // tslint:disable-next-line:no-unused-expression triple-equals
    // if (a != 0) {
    //   document.getElementById('myTab').scrollLeft -= 200;
    // }
  }

  // tslint:disable-next-line:typedef
  scrollLi() {
    // const element = document.getElementById('myTab');
    // const a = element.scrollLeft;
    const rightBtn = document.querySelector('ul#myTab');
    rightBtn.scrollLeft += 200;
    // tslint:disable-next-line:no-unused-expression triple-equals
    // if (a != 0) {
    //   document.getElementById('myTab').scrollLeft -= 200;
    // }
  }
}
