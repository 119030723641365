import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { $ } from 'protractor';

// @ts-ignore
@Injectable({ providedIn: 'root' })
export class BabyfaceService {
  private babyUrlV2 = 'web/v2/babyface/';
  private babyUrlV1 = 'web/v2/';
  private token = JSON.parse(window.localStorage.getItem('token'));
  constructor(private httpClient: HttpClient) {}
  getFrame(): Observable<any> {
    return this.httpClient.get<any>(
      environment.apiBase + this.babyUrlV2 + 'getFrame/web'
    );
  }
  createOrder(form: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token.value,
    };
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'store/web',
      form,
      { headers }
    );
  }
  getItem(): Observable<any> {
    return this.httpClient.get<any>(
      environment.apiBase + this.babyUrlV2 + 'getItem/web'
    );
  }
  getCart(status: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token.value,
    };
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'my/web?status=' + status,
      null,
      { headers }
    );
  }
  getReview(): Observable<any> {
    return this.httpClient.get<any>(
      environment.apiBase + this.babyUrlV2 + 'review/web'
    );
  }
  postLogin(form: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV1 + 'auth/loginSocial/web',
      form
    );
  }
  getPost(form: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'post/list',
      form
    );
  }
  postDetal(id: any): Observable<any> {
    let form = {
      id: id,
    };
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'post/detail',
      form
    );
  }
  postRegiterSocial(form: any): Observable<any> {
    form.birthday_baby = new Date(form.birthday_baby).getTime();
    form.birthday_preg = new Date(form.birthday_preg).getTime();
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV1 + 'auth/registerSocial/web',
      form
    );
  }
  cancelOrder(from: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token.value,
    };
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'cancel/web',
      from,
      { headers }
    );
  }
  getDetail(from: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token.value,
    };
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'detail',
      from,
      { headers }
    );
  }
  payment(from: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token.value,
    };
    return this.httpClient.post<any>(
      environment.apiBase + this.babyUrlV2 + 'repay',
      from,
      { headers }
    );
  }
}
