<div class="baby-face-cart">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-12" style="padding: 0px" *ngIf="formData.vnp_ResponseCode == 0">
        <div class="img-succes">
          <img src="assets/images/bg-success.png" alt="Errror" width="100%">
        </div>
        <div class="info-order" style="padding: 16px">
          <div class="name-order">
            <img src="assets/images/rectangle.png" alt="Error">
            <div>
              <p class="name">Ảnh dự sinh Babyface</p>
              <p>x1</p>
            </div>
          </div>
          <hr>
          <div class="price-order">
            <span>Xuất ảnh cơ bản</span>
            <span>{{price}}</span>
          </div>
          <hr>
          <div class="information-line">
            <ul>
              <li>
                <span>Mã đơn hàng</span>
                <span>BABY{{formData.vnp_TxnRef}}BEBERIA</span>
              </li>
              <li>
                <span>Thời gian đăng ký</span>
                <span>{{(formData.vnp_PayDate).slice(6,8)}}-{{(formData.vnp_PayDate).slice(4,6)}}-{{(formData.vnp_PayDate).slice(0,4)}} {{(formData.vnp_PayDate).slice(8,10)}}:{{(formData.vnp_PayDate).slice(10,12)}}:{{(formData.vnp_PayDate).slice(12,14)}}</span>
              </li>
              <li>
                <span>Thời gian gửi kết quả(dự kiến)</span>
                <span>{{formattedTime}}</span>
              </li>
            </ul>
          </div>

          <div class="note-order">
            <p><strong>Lưu ý:</strong><br> Sau 5 ngày mẹ vẫn chưa nhận được kết quả thì hãy inbox fanpage Beberia để được hỗ trợ kịp thời</p>
          </div>
          <div class="btn-footer" style="display:flex;justify-content: space-evenly">
            <a href="/" style="width: 48%;">
              <button type="button" class="btn btn-primary btn-lg">Trang chủ</button>
            </a>
            <a href="/detail?id={{formData.vnp_TxnRef}}" style="width: 48%;">
              <button type="button" class="btn btn-secondary btn-lg">Trạng thái đơn hàng</button>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-12" style="padding: 0px" *ngIf="formData.vnp_ResponseCode != 0">
        <div class="img-succes">
          <img src="assets/images/bg_error.png" alt="Errror" width="100%">
        </div>
        <div class="info-order" style="padding: 16px">
          <div class="name-order">
            <img src="assets/images/rectangle.png" alt="Error">
            <div>
              <p class="name">Ảnh dự sinh Babyface</p>
              <p>x1</p>
            </div>
          </div>
          <hr>
          <div class="price-order">
            <span>Xuất ảnh cơ bản</span>
            <span>{{price}}</span>
          </div>
          <hr>
          <div class="information-line">
            <ul>
              <li>
                <span>Mã đơn hàng</span>
                <span>BABY{{formData.vnp_TxnRef}}BEBERIA</span>
              </li>
              <li>
                <span>Thời gian đăng ký</span>
                <span>{{(formData.vnp_PayDate).slice(6,8)}}-{{(formData.vnp_PayDate).slice(4,6)}}-{{(formData.vnp_PayDate).slice(0,4)}} {{(formData.vnp_PayDate).slice(8,10)}}:{{(formData.vnp_PayDate).slice(10,12)}}:{{(formData.vnp_PayDate).slice(12,14)}}</span>
              </li>
            </ul>
          </div>

          <div class="note-order">
            <p><strong>Lưu ý:</strong><br> Nếu mẹ gặp khó khăn thi hãy inbox fanpage Beberia để được hỗ trợ kịp thời!</p>
          </div>
          <div class="btn-footer" style="    display: flex;justify-content: space-evenly;width: 100%;">
            <a href="/" style="width: 48%;">
              <button type="button" class="btn btn-primary btn-lg">Trang chủ</button>
            </a>
            <a href="/order" style="width: 48%;">
              <button type="button" class="btn btn-secondary btn-lg">Đăng ký lại</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
