<app-header></app-header>
<div class="cart-title d-flex">
  <span>
    <a (click)="back()" href="cam-nang" style="color: #000">
      <i class="fa fa-angle-left"></i>
      Cẩm nang
    </a>
  </span>
</div>
<div *ngIf="detail" class="mx-lg-3 mx-3 detail-post">
  <span class="fw-7 fs-24 title">{{ detail.title }}</span>
  <div class="content pt-2">
    <span [innerHTML]="detail.quote" class="fw-5"> </span>
    <p class="post-date fw-4 fs-13">{{ getTime(detail.created) }}</p>
  </div>
  <!-- <div class="detail-img">
    <img src="{{detail.url_image}}" alt="" width="100%" />
  </div> -->
  <div [innerHTML]="detail.content" class="content"></div>
  <hr />
  <div class="btn-share">
    <a href="{{ getLink() }}" target="_blank" rel="noopener noreferrer">
      <button>
        <img src="assets/images/icon-fb.png" alt="" />
        Chia sẻ
      </button>
    </a>
  </div>
  <div class="post-more pt-3">
    <span class="fw-7 fs-18">Bài viết liên quan</span>
    <div class="post pt-2">
      <a
        *ngFor="let item of rand"
        class="post-list-child row pb-md-4 pb-2 text-decoration-none"
        href="{{ '/chi-tiet/' + convertToSlug(item.title, item.id) }}"
        href="javascript:void(0)"
        style="color: #000000"
      >
        <div class="post-img col-3">
          <a href="{{ '/chi-tiet/' + convertToSlug(item.title, item.id) }}">
            <img src="{{ item.media[0].link }}" alt="" width="100%" />
          </a>
        </div>
        <div class="post-content px-0 col-9">
          <span class="content fw-7 fs-14">
            {{ item.title }}
          </span>
          <p class="post-date fw-4 fs-13 position-absolute bottom-0">
            {{ getTime(item.created) }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="cam-nang container"></div>
<app-contact></app-contact>
<app-footer></app-footer>
