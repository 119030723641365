<app-header></app-header>
<div class="cart-title d-flex">
  <span>
    <a href="javascript:history.go(-1);" style="color: #000">
      <i class="fa fa-angle-left"></i>
      Danh sách tin tức
    </a>
  </span>
</div>
<div class="category mx-lg-3 mx-3">
  <div class="category-list">
    <a
      *ngFor="let item of listPost"
      class="post-list-child row pb-md-4 pb-2 text-decoration-none"
      [routerLink]="['/chi-tiet', convertToSlug(item.title, item.id)]"
      href="javascript:void(0)"
      style="color: #000000"
    >
      <div class="post-img col-3">
        <img
          class="w-100"
          src="{{ item.media[0].link }}"
          alt="{{ item.title }}"
        />
      </div>
      <div href="javascript:void(0)" class="post-content px-0 col-8">
        <span class="content fw-7 fs-14">
          {{ item.title }}
        </span>
        <p class="post-date fw-4 fs-13">
          {{ getTime(item.created) }}
        </p>
      </div>
    </a>
    <div *ngIf="page > 1" class="post-see-more text-center">
      <button (click)="more()" type="button" class="">Xem thêm</button>
    </div>
  </div>
</div>
<app-contact></app-contact>
<app-footer></app-footer>
