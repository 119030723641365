import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  price = '';
  formData: any;
  day: number ;
  formattedTime: string;
  constructor(private activatedRoute: ActivatedRoute) {
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // tslint:disable-next-line:radix
      this.price = (parseInt(params.vnp_Amount) / 100).toLocaleString('it-IT', {style : 'currency', currency : 'VND'}).toString();
      this.formData = params;
      // tslint:disable-next-line:max-line-length
      this.day = Math.floor(new Date((this.formData.vnp_PayDate).slice(4, 6) + '-' + (this.formData.vnp_PayDate).slice(6, 8) + '-' + (this.formData.vnp_PayDate).slice(0, 4)).getTime() / 1000.0) + 259200;
      const c = new Date(this.day * 1000);
      // tslint:disable-next-line:max-line-length
      this.formattedTime = ((c.getDate() < 10 ? '0' : '') + c.getDate()) + '/' + (((c.getMonth() + 1) < 10 ? '0' : '') + (c.getMonth() + 1)) + '/' + c.getFullYear();

    });

  }

  ngOnInit(): void {
  }

}
