import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BabyfaceService} from '../../api/babyface.service';
import {Frame} from '../../Model/Frame';
import {LocationService} from '../../api/location.service';
import {City} from '../../Model/City';
import {District} from '../../Model/District';
import { Item } from 'src/app/Model/Item';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  listChild = [];
  listImageDetail = [1,2,3,4,5];
  listParent = [];
  listCity: City[] = [];
  listDistrict: District[] = [];
  listFrame: Frame[] = [];
  listItem: Item[] = [];
  datas: Item[] = [];
  step = 1;
  uploadForm: FormGroup;
  validateFileChild = false;
  frameSample = '';
  dateComplete = '';
  dateDelivery = '';
  constructor(private formBuilder: FormBuilder, private babyfaceService: BabyfaceService, private locationService: LocationService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.uploadForm = this.formBuilder.group({
      item_id: [1, Validators.required],
      contactEmail: ['', Validators.required],
      contactName: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      message: '',
      frame: ['DEFAULT', Validators.required],
      pay_type: [1, Validators.required],
      filesChild: [],
      filesParent: [this.listParent]
    });
    this.getFrame();
    //this.getCity();
    this.getItem();
    this.dateComplete = new Date((new Date().getTime() + 259200000)).toLocaleDateString();
    this.dateDelivery = new Date((new Date().getTime() + 345600000)).toLocaleDateString();
  }
  getImage(event: any, type: number): void
  {
    if (event.target.files.length > 5)
    {
      alert('Không được vượt quá 5 ảnh');
    }else {
      this.readImage(event.target.files, type);
    }
  }
  readImage(files, type: number): void
  {
    for (const file of files) {
      if (file.size / 1024 / 1024  > 5)
      {
        alert('Hình ảnh không được vượt quá 5MB');
        break;
      }
      // tslint:disable-next-line:triple-equals
      if (this.listChild.length == 5)
      {
        alert('Không vượt quá 5 ảnh tải lên');
        break;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (type === 1)
        {
          this.listChild.push(e.target.result);
          this.validateFileChild = false;
          this.listImageDetail.pop();
          this.uploadForm.controls.filesChild.setValue(this.listChild);
        }
        if (type === 2)
        {
          this.listParent.push(e.target.result);
          this.uploadForm.controls.filesParent.setValue(this.listParent);
        }
      };
      reader.readAsDataURL(file);
    }
  }
  nextStep(): void{
    this.getItem();
    if (this.step === 1)
    {
      this.validateFileChild = false;
      if (this.listChild.length === 0)
      {
        this.validateFileChild = true;
        window.scroll(0, 0);
        alert('Vui lòng chọn ít nhất 1 ảnh tải lên');
      }
      if (!this.validateFileChild)
      {
        this.step += 1;
        window.scroll(0, 0);
      }
    }else {
      this.validateFileChild = false;
      if (this.listChild.length === 0)
      {
        this.validateFileChild = true;
        window.scroll(0, 0);
        alert('Vui lòng chọn ít nhất 1 ảnh tải lên');
      }
      if (!this.validateFileChild)
      {
        this.step += 1;
        window.scroll(0, 0);
      }
    }
  }
  previousStep(): void{
    this.step -= 1;
  }
  getFrame(): void
  {
    this.babyfaceService.getFrame().subscribe((resp) => {
      if (resp.status === 200){
        this.listFrame = resp.data;
        this.frameSample = this.listFrame[0].link_sample;
      }
    });
  }
  // tslint:disable-next-line:typedef
  frameActive(sample: string): void
  {
    this.frameSample = sample;
  }
  createOrder(): void
  {
    document.getElementById('cover-spin').style.display = 'block';
    if (this.uploadForm.valid)
    {
      this.babyfaceService.createOrder(this.uploadForm.value).subscribe((resp) => {
        if (resp.status === 200)
        {
          document.getElementById('cover-spin').style.display = 'none';
          window.location.href = resp.data.payment_url;
        }else{
          document.getElementById('cover-spin').style.display = 'none';
          document.getElementById('btn-payment-error').click();
        }
      });
    }else {
      document.getElementById('cover-spin').style.display = 'none';
      window.scroll(0, 0);
    }
  }
  removeImage(event: any, mediaIndex: number, type: number): void {
    event.preventDefault();
    if (type === 1)
    {
      this.listChild.splice(mediaIndex, 1);
      this.listImageDetail.push(7);
    }
    if (type === 2)
    {
      this.listParent.splice(mediaIndex, 1);
    }
  }
  getCity(): void {
    this.locationService.getList().subscribe((reps) => {
      if (reps.status === 200)
      {
        this.listCity = reps.data.datas;
      }
    });
  }
  getDistrict(): void
  {
    this.locationService.getDistrict(this.uploadForm.get('city_code').value).subscribe((resp) => {
      if (resp.status === 200)
      {
        this.listDistrict = resp.data.datas;
      }
    });
  }
  getItem(): void {
    this.babyfaceService.getItem().subscribe((resp) => {
      if (resp.status === 200)
      {
        this.listItem = resp.data.datas[0];
      }
    });
  }
}
