import { ChildComponent } from './component/detail-category/child.component';
import { PostComponent } from './component/post/post.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './component/register/register.component';
import { SuccessComponent } from './component/success/success.component';
import { HomeComponent } from './component/view/home/home.component';
import { ReviewsComponent } from './component/view/reviews/reviews.component';
import { LoginComponent } from './component/view/login/login.component';
import { InfoComponent } from './component/view/info/info.component';
import { BabyfaceComponent } from './component/view/babyface/babyface.component';
import { CartComponent } from './component/view/cart/cart.component';
import { OrderComponent } from './component/order/order.component';
import { PolicyComponent } from './component/policy/policy/policy.component';
import { DetailComponent } from './component/detail/detail.component';
import { AuthGuard } from './services/AuthGuard';
import { QuestionComponent } from './component/policy/question/question.component';
import { RefundComponent } from './component/policy/refund/refund.component';
import { AboutComponent } from './component/policy/about/about.component';
import { CancelOrderComponent } from './component/cancel-order/cancel-order.component';
import { PrivacyComponent } from './component/policy/privacy/privacy.component';
import { PaymentComponent } from './component/policy/payment/payment.component';
import { WarrantyComponent } from './component/policy/warranty/warranty.component';
import { ShopComponent } from './component/shop/shop.component';
import { VideoComponent } from './component/video/video.component';
import { DetailComponent as DetailPost } from './component/post-detail/detail.component';
import { CategoryComponent } from './component/category/category.component';

const routes: Routes = [
  { path: '', component: BabyfaceComponent },
  {
    path: 'gio-hang',
    component: CartComponent,
    canActivate: [AuthGuard],
  },
  { path: 'detail', component: DetailComponent, canActivate: [AuthGuard] },
  {
    path: 'cancel-order',
    component: CancelOrderComponent,
    canActivate: [AuthGuard],
  },
  { path: 'order', component: OrderComponent, canActivate: [AuthGuard] },
  { path: 'dang-ky', component: RegisterComponent },
  { path: 'reviews', component: ReviewsComponent },
  { path: 'success', component: SuccessComponent },
  {
    path: 'cam-nang',
    component: PostComponent,
  },
  { path: 'chi-tiet/:slug', component: DetailPost },
  { path: 'danh-muc', component: CategoryComponent },
  { path: 'bai-viet', component: ChildComponent },
  { path: 'shop', component: ShopComponent },
  { path: 'kho-luu-tru-video', component: VideoComponent },
  { path: 'dang-nhap', component: LoginComponent },
  {
    path: 'thong-tin-ca-nhan',
    component: InfoComponent,
    canActivate: [AuthGuard],
  },
  { path: 'babyface', component: BabyfaceComponent },
  { path: 'dieu-khoan-su-dung', component: PolicyComponent },
  { path: 'cau-hoi-thuong-gap', component: QuestionComponent },
  { path: 'chinh-sach-hoan-tien', component: RefundComponent },
  { path: 've-babyface', component: AboutComponent },
  { path: 'chinh-sach-bao-mat', component: PrivacyComponent },
  { path: 'phuong-thuc-thanh-toan', component: PaymentComponent },
  { path: 'chinh-sach-bao-hanh-doi-tra', component: WarrantyComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
