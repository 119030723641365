import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { group } from '@angular/animations';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
})
export class VideoComponent implements OnInit {
  list: Array<any>;
  open: boolean;
  true: true;
  step: number;
  formVideo = this.formBuilder.group({
    hospital: '',
    prcode: '',
  });
  result: Array<any>;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.list = list;
    this.open = false;
    this.step = 1;

    let token = localStorage.getItem('token');
    const item = JSON.parse(token);
    if (
      token == null ||
      Math.floor(new Date().getTime() / 1000) > item.expiry
    ) {
      this.step = 0;
    }
  }

  // tslint:disable-next-line:typedef
  showItem() {
    if (this.open) {
      this.open = false;
    } else {
      this.open = true;
    }
  }

  // tslint:disable-next-line:typedef
  onSubmit() {}

  // tslint:disable-next-line:typedef variable-name
  setStep(number: number, event: MouseEvent) {
    if (!this.formVideo.value.hospital) {
      alert('Vui lòng chọn bệnh viện/ Phòng khám!');
      event.stopPropagation();
    } else {
      this.step = number;
    }
  }
}
// @ts-ignore
export const list = [
  {
    title: 'Bệnh viện Thu Cúc',
    value: 'thucuc',
  },
  {
    title: 'Bệnh viện Hồng ngọc',
    value: 'hongngoc',
  },
  {
    title: 'Bệnh viện Vinmec',
    value: 'vinmec',
    child: [
      {
        title: 'Vinmec Chi nhánh 1',
        value: 'vinmec1',
      },
      {
        title: 'Vinmec Chi nhánh 2',
        value: 'vinmec2',
      },
      {
        title: 'Vinmec Chi nhánh 3',
        value: 'vinmec3',
      },
    ],
  },
];
