import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

// @ts-ignore
@Injectable({providedIn: 'root'})
export class LocationService {
  private babyUrl = 'web/v2/location/';
 
  constructor(private httpClient: HttpClient) {
  }
  getList(): Observable<any> {
    return this.httpClient.get<any>(environment.apiBase + this.babyUrl + 'get/web?paginate=64');
  }
  getDistrict(locationCode: string): Observable<any> {
    return this.httpClient.post<any>(environment.apiBase + this.babyUrl + 'district', {location_code: locationCode});
  }
}
