<app-header></app-header>
<div class="cart-title d-flex">
  <span>
    <a href="javascript:history.go(-1);" style="color: #000">
      <i class="fa fa-angle-left"></i>
      Chuẩn bị trước khi sinh
    </a>
  </span>
</div>
<div
  class="category container"
  [style.background]="'url(assets/images/category-bg.png)'"
  [style.background-repeat]="'no-repeat'"
  [style.background-size]="'100% 100%'"
>
  <div class="category-list">
    <div *ngFor="let item of [1, 2, 3, 4]" class="content mb-2">
      <a [routerLink]="['/danh-muc', item]">
        <img src="assets/images/icon-dinh-duong.png" height="100%" alt="" />
        <span class="title fw-4 fs-16">Dinh dưỡng</span>
      </a>
    </div>
  </div>
</div>
<app-contact></app-contact>
<app-footer></app-footer>
