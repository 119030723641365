import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isLogin = null;
  check = 0;
  constructor() { }

  ngOnInit(): void {
    this.isLogin = (JSON.parse(localStorage.getItem('token'))) ? JSON.parse(localStorage.getItem('token')).value : null;
  }
  checklogin(){
    if(this.isLogin == null){
      $("#myModalCartLogin").modal('show');
    }
  }
}
