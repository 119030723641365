<app-header></app-header>
<div class="baby-face-video">
  <div class="cart-title d-flex">
    <span
      ><a href="javascript:history.go(-1);" style="color: #000"
        ><i class="fa fa-angle-left"></i>Kho lưu video siêu âm</a
      ></span
    >
  </div>
  <div *ngIf="step == 0" class="mx-3">
    <img src="assets/images/banner-video.jpg" alt="" width="100%" />
    <img class="pt-5" src="assets/images/login-video.png" alt="" width="100%" />
    <button
      data-toggle="modal"
      data-target="#myModal"
      class="mt-2 btn-login-video mx-auto d-block"
    >
      Đăng nhập ngay
    </button>
  </div>
  <!--  <div class="shop text-center">-->
  <!--    <img src="assets/images/dang-phat-trien.png" alt="">-->
  <!--  </div>-->
  <div class="form-select" *ngIf="step != 0">
    <h4>Chọn bệnh viện/ Phòng khám</h4>
    <p>Bệnh viện/Phòng khám mà mẹ đến siêu âm</p>
    <form action="" (ngSubmit)="onSubmit()" [formGroup]="formVideo">
      <div class="step1" *ngIf="step == 1">
        <div class="" *ngFor="let item of list">
          <div class="not-child" *ngIf="!item.child">
            <label for="{{ item.value }}">{{ item.title }}</label>
            <input
              class="float-right"
              formControlName="hospital"
              type="radio"
              name="drone"
              value="{{ item.value }}"
            />
          </div>
          <div class="hospital-child" *ngIf="item.child">
            <div class="lable-hospital" (click)="showItem()">
              {{ item.title }}
              <i class="fa fa-angle-up float-right" *ngIf="open"></i>
              <i class="fa fa-angle-down float-right" *ngIf="!open"></i>
            </div>
            <div *ngIf="open">
              <div class="grow-child" *ngFor="let child of item.child">
                <label for="{{ child.value }}">{{ child.title }}</label>
                <input
                  class="float-right"
                  formControlName="hospital"
                  type="radio"
                  name="drone"
                  value="{{ child.value }}"
                />
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="btn-submit">
          <button type="submit" (click)="setStep(2)">Tiếp tục</button>
        </div>
      </div>
      <div class="step2" *ngIf="step == 2">
        <div class="grow">
          <img src="/assets/images/barcode1.png" alt="" />
          <div class="input-qr">
            <label for="qrcode">Nhập/ Quét mã trên giấy khám</label>
            <div class="flex">
              <input
                type="text"
                id="qrcode"
                name="qrcode"
                placeholder="Nhập mã hoặc quét QR"
              />
              <p class="hr"></p>
              <img src="/assets/images/u_qrcode-scan.png" alt="" />
            </div>
          </div>
        </div>
        <div class="btn-submit">
          <button type="submit">Tiếp tục</button>
        </div>
      </div>
    </form>
  </div>
  <div class="result-qrcode" *ngIf="result">
    <div class="step2">
      <div class="grow">
        <div class="input-qr">
          <label for="qrcode">Nhập/ Quét mã trên giấy khám</label>
          <div class="flex">
            <form action="" (ngSubmit)="onSubmit()" [formGroup]="formVideo">
              <input
                formControlName="prcode"
                type="text"
                id="qr"
                name="qrcode"
                placeholder="Nhập mã hoặc quét QR"
              />
              <p class="hr"></p>
              <img src="/assets/images/u_qrcode-scan.png" alt="" />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="qr stel_result1">
      <img src="assets/images/bar-code.png" alt="" />
      <div
        class="result"
        style="background-image: url(assets/images/result.png)"
      >
        <p class="video"><i class="fa-solid fa-video"></i> 2</p>
        <p class="image"><i class="fa-solid fa-image"></i> 4</p>
      </div>
      <div style="margin-top: 10px">
        <p><Strong>Tuần 7 - 28/04/2022</Strong></p>
      </div>
    </div>
    <div class="detail-result">
      <h2
        style="
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          color: #141f3c;
        "
      >
        Video siêu âm của bạn
      </h2>
      <div class="week">
        <h4>Tuần 7 - 28/04-2022</h4>
        <div class="file-result">
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
          <div class="file">
            <img src="/assets/images/result.png" alt="" />
            <p class="icon"><i class="fa-solid fa-video"></i></p>
            <p class="time">Tuần 7 - 28/04/2022</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal right fade"
  id="myModal"
  tabindex="-1"
  role="dialog"
  style="padding-right: 0px"
  aria-labelledby="myModalLabel2"
>
  <div
    class="modal-dialog float-right-modal"
    role="document"
    style="background-color: white; margin: 0"
  >
    <div class="modal-content">
      <div class="modal-body" style="padding: 0px">
        <app-login></app-login>
      </div>
    </div>
    <!-- modal-content -->
  </div>
  <!-- modal-dialog -->
</div>
