<div class="container mt-4 mb-2 service">
  <div class="row">
   <div class="col-12 service-title">
    <h3>Dịch vụ của chúng tôi</h3>
    <span>Sử dụng công nghệ AI để hoàn thiện bức ảnh siêu âm 3D</span>
   </div>
   <div *ngFor="let product of item" class="col-12 service-box"  >
      <a href="/babyface">
        <div class="img-service">
          <img src="assets/images/baby.gif" alt="" width="100%" height="100%">
        </div>
        <div class="title-service">
          <span>BabyFace - Dự đoán gương mặt em bé</span>
          <p>Công nghệ độc quyền phân tích hình ảnh siêu âm và dự đoán gương mặt em bé trước khi sinh</p>
        </div>
        <div class="price-service d-flex">
          <span class="price-sale mr-2">{{ product.sale_price }}</span>
          <span class="price-format mr-2">{{ product.price_format }}</span>
          <span class="sale">-{{ product.sale * 100 }}%</span>
        </div>
      </a>
   </div>
  </div>
</div>
