<div style="background-color: #F6F6F6">
  <div class="babyface-contact">
    <div class="float-left contact-logo w-100">
      <img src="assets/images/logo-color.png" class="img-fluid img-size">
      <h3 class="text-size">Công ty TNHH Beberia Việt Nam</h3>
    </div>
    <div class=" float-left contact-info w-100">
      <span class="contact-mst">ĐKKD số: 0109402765 do Sở Kế Hoạch và Đầu Tư Thành Phố Hà Nội cấp ngày 04/11/2020</span>
      <span class="contact-adr d-flex"><img src="assets/images/location.png" alt="" height="24px" width="24px">
        Số 9 Ngõ 106 đường Hoàng Quốc Việt, Phường Nghĩa Tân, Quận Cầu Giấy, Thành phố Hà Nội, Việt Nam
      </span>
      <span class="contact-adr d-flex"><img src="assets/images/phone.png" alt="" height="24px" width="24px">
        0782 333 035
      </span>
      <span class="contact-adr d-flex"><img src="assets/images/mail.png" alt="" height="24px" width="24px">
        beberiavn@gmail.com
      </span>
    </div>
    <div class="row w-100">
      <div class="col-12 contact-download">
        <h3>Tải ứng dụng</h3>
        <div class="row contact-chplay-appstore-pr">
          <div class="col contact-chplay-appstore">
            <a href="https://play.google.com/store/apps/details?id=com.gngbc.beberia" target="_blank" >
              <img src="assets/images/ch-play.png" class="img-thumbnail img-size-store" width="135px" height="40px">
            </a>
            <a href="https://apps.apple.com/vn/app/beberia-m%E1%BA%B9-v%C3%A0-b%C3%A9/id1487920280" target="_blank" class="app-store" >
              <img src="assets/images/app-store.png" class="img-thumbnail img-size-store"  width="135px" height="40px"> 
            </a>
          </div>
          <div class="col contact-qr">
            <a href="https://play.google.com/store/apps/details?id=com.gngbc.beberia" target="_blank" class="text-white">
              <img src="assets/images/qr-code.png" class="pr-code">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-link w-100">
      <div class="contact-link-1">
        <a href="/ve-babyface" target="_blank">Về Babyface</a>
        <span></span>
        <a href="/dieu-khoan-su-dung" target="_blank">Điều khoản sử dụng</a>
      </div>
      <div class="contact-link-2">
        <a href="/chinh-sach-bao-mat" target="_blank">Chính sách bảo mật</a>
        <a class="d-none" href="/chinh-sach-hoan-tien" target="_blank">Chính sách hoàn tiền</a>
        <span></span>
        <a href="/cau-hoi-thuong-gap" target="_blank">Câu hỏi thường gặp</a>
      </div>
      <div class="contact-link-2 text-center">
        <a href="/phuong-thuc-thanh-toan" target="_blank">Phương thức thanh toán</a>
        <span></span>
        <a href="/chinh-sach-bao-hanh-doi-tra" target="_blank">Chính sách bảo hành, đổi trả</a>
      </div>
    </div>
    <div class="bo-cong-thuong">
      <a target="_blank" href='http://online.gov.vn/Home/WebDetails/97682'>
        <img alt='chung-nhan-cua-bo-cong-thuong' title='chung-nhan-cua-bo-cong-thuong' src='assets/images/bo-cong-thuong.png'/>
      </a>
    </div>
  </div>
</div>