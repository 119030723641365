import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../environments/environment';

// @ts-ignore
@Injectable({providedIn: 'root'})
export class AesService {
  private secretKey = environment.keySecret;
  // tslint:disable-next-line:ban-types
  encryptData(data: Object): string {
    const keyParse = CryptoJS.enc.Base64.parse(this.secretKey);
    let iv = CryptoJS.lib.WordArray.random(16);
    const options = {
      iv
    };
    let value = CryptoJS.AES.encrypt(JSON.stringify(data), keyParse, options);
    value = value.toString();
    iv = CryptoJS.enc.Base64.stringify(iv);

    let results = JSON.stringify({
      iv,
      value,
      mac: CryptoJS.HmacSHA256(iv + value, keyParse).toString()
    });
    results = CryptoJS.enc.Utf8.parse(results);
    return CryptoJS.enc.Base64.stringify(results);
  }
  // tslint:disable-next-line:ban-types
  decryptData(data: string): Object
  {   
    const keyParse = CryptoJS.enc.Base64.parse(this.secretKey);
    const encrypted = JSON.parse(atob(data));
    const options = {
      iv: CryptoJS.enc.Base64.parse(encrypted.iv)
    };
    const decrypted = CryptoJS.AES.decrypt(encrypted.value, keyParse, options);
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
}
