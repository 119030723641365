import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css'],
})
export class InfoComponent implements OnInit {
  user;
  isHome = 1;
  constructor(private router: Router) {}

  ngOnInit(): void {
    if (this.router.url == '/') {
      this.isHome = 0;
    }
    let getUser = localStorage.getItem('user');
    this.user = JSON.parse(getUser);
  }
  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }
}
