import { ChildComponent } from './component/detail-category/child.component';
import { CategoryComponent } from './component/category/category.component';
import { DetailComponent as PostDetail } from './component/post-detail/detail.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

/**
 * carousel slide
 */
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { ContactComponent } from './component/contact/contact.component';
import { FooterComponent } from './component/footer/footer.component';
import { SilderComponent } from './component/silder/silder.component';
import { RegisterComponent } from './component/register/register.component';
import { ProductComponent } from './component/product/product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './interceptors';
import { SuccessComponent } from './component/success/success.component';
import { HomeComponent } from './component/view/home/home.component';
import { ReviewComponent } from './component/review/review.component';
import { UploadComponent } from './component/upload/upload.component';
import { BabyfaceComponent } from './component/view/babyface/babyface.component';
import { CartComponent as CartComponentView } from './component/view/cart/cart.component';
import { CartComponent } from './component/cart/cart.component';
import { OrderComponent } from './component/order/order.component';
import { LoginComponent } from './component/view/login/login.component';
import { InfoComponent } from './component/view/info/info.component';
import { AuthGuard } from './services/AuthGuard';
import { AuthService } from './services/AuthService';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { DetailComponent } from './component/detail/detail.component';
import { CancelOrderComponent } from './component/cancel-order/cancel-order.component';
import { ShopComponent } from './component/shop/shop.component';
import { VideoComponent } from './component/video/video.component';
import { PostComponent } from './component/post/post.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContactComponent,
    FooterComponent,
    SilderComponent,
    RegisterComponent,
    SuccessComponent,
    ProductComponent,
    HomeComponent,
    ReviewComponent,
    BabyfaceComponent,
    UploadComponent,
    CartComponentView,
    CartComponent,
    OrderComponent,
    LoginComponent,
    InfoComponent,
    DetailComponent,
    CancelOrderComponent,
    ShopComponent,
    VideoComponent,
    PostComponent,
    PostDetail,
    CategoryComponent,
    ChildComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IvyCarouselModule,
  ],
  providers: [
    httpInterceptorProviders,
    AuthGuard,
    AuthService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
