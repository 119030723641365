<app-header></app-header>
<div id="cover-spin"></div>
<div class="baby-face-cart" style="background: #F6F6F6">
  <div class="cart-title d-flex" style="background: #FFFFFF;">
    <span><a href="javascript:history.go(-1);" style="color:#000;"><i class="fa fa-angle-left" ></i>Hủy đơn hàng</a></span>
  </div>
  <div class="success-cancel-order" id="success-cancel-order" style="margin-bottom: 15px;background: #0299FE;padding: 16px">
    <p *ngIf="listItem['status'] == 0" style="margin-bottom: 0px">
      <span style="color:#FFFFFF;font-size: 16px;font-weight: 700"><img src="assets/images/check_ring_light.png" alt=""> Đơn hàng đã hủy thành công</span>
      <span *ngIf="listItem['status']" style="font-size: 14px;color: #FFFFFF">
        <br>Đối với thẻ nội địa / ví điện tử từ 3-8 ngày làm việc. <br> Đối với thẻ quốc tế tối đa 90 ngày làm việc  thời gian cụ thể do mỗi ngân hàng và tổ chức phát hành thẻ quy định
      </span>
    </p>
  </div>
  <div class="form-cancel-order">
    <div style="background: #FFFFFF;padding: 16px">
      <div class="header-detail" style="display:flex;justify-content: space-between">
          <span style="font-size: 14px;color: #FD799D">
            <img src="assets/images/Frame.png" alt="Error">
            Mã đơn hàng: <strong style="color:#000;font-weight: 500">{{listItem['order_code']}}</strong>
          </span>
        <span style="color: #0299FE;cursor: pointer;" (click)="copOrder()">
          Sao chép
          </span>
      </div>
      <div class="time" style="font-size: 13px;padding-left: 20px">
        Ngày yêu cầu: {{listItem['created']}}
      </div>
    </div>
    <div style="background: #FFFFFF;padding: 16px;margin-top: 10px">
      <form [formGroup]="uploadForm" (submit)="cancelOrder()">
        <div style="margin-bottom: 50px">
          <label style="font-size: 14px;font-weight: 700;color: #4D4D4D">Lý do hủy đơn hàng</label>
          <input type="text" class="form-control bg-white  payment-input" id="cancel-reason"
                 formControlName="cancel_reason" placeholder="Vui lòng cho biết lý do hủy đơn hàng">
          <span *ngIf="uploadForm.controls.cancel_reason.invalid "  class="text-danger" id="error-form" style="display:none;">
                    <span *ngIf="uploadForm.controls.cancel_reason.errors.required" style="color: #FD799D;font-size: 13px;font-style: italic;">
                      Vui lòng nhập lý do hủy đơn hàng
                    </span>
                  </span>
        </div>
        <a style="cursor: pointer;font-size: 16px" class="btn-cancel-order" id="btn-cancel" (click)="cancelOrder()"><button type="button" class="btn btn-primary btn-lg btn-block" style="background: #FFFFFF;border-color: #FD799D;color: #FD799D">
          Hủy đơn
        </button></a>
      </form>
      <div id="btn-return" style="display: none;justify-content: space-evenly;">
        <a href="/gio-hang" style="cursor: pointer;font-size: 16px;width: 45%" class="btn-cancel-order"><button type="button" class="btn btn-primary btn-lg btn-block" style="background: #FFFFFF;border-color: #FD799D;color: #FD799D">
          Trở về
        </button></a>
        <a href="/order" style="cursor: pointer;font-size: 16px;width: 45%" class="btn-cancel-order" ><button type="button" class="btn btn-primary btn-lg btn-block" style="background: #FD799D;color: #FFFFFF;border-color: #FFFFFF">
          Đặt lại ảnh
        </button></a>
      </div>
    </div>
  </div>
</div>
<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id="cancel-success" style="display:none;">
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="transform: translate(0,20%) !important;">
    <div class="modal-content">
      <div class="modal-body modal-cancel-btn" style="text-align: center" >
        <p>
          <img src="assets/images/cancel-order-success.png" alt="Error" width="100px">
        </p>
        <p style="font-size: 18px;font-weight: 700;color: #141F3B;margin-bottom: 10px">Đơn hàng đã hủy</p>
        <p style="font-size: 14px;font-weight: 400;color: #5D5B5B">Đơn hàng của bạn đã được <br>hủy thành công!</p>
        <a href="/gio-hang" >
          <button  type="button" class="btn btn-primary btn-lg btn-block" style="background: #FFFFFF;border-color: #FD799D;color: #FD799D;margin-bottom: 10px">Quản lý đơn hàng</button>
        </a>
        <a href="/">
          <button type="button" class="btn btn-secondary btn-lg btn-block" style="color:#FFFFFF;background: #FD799D;border-color: #FD799D">Về trang chủ Babyface</button>
        </a>
      </div>
    </div>
  </div>
</div>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#cancelFalse" id="cancel-false" style="display: none">
</button>

<!-- Modal -->
<div class="modal fade" id="cancelFalse" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" style="transform: translate(0,20%) !important;">
    <div class="modal-content">
      <div class="modal-body modal-cancel-btn" style="text-align: center" >
        <p>
          <img src="assets/images/error3.svg" alt="Error" width="100px">
        </p>
        <p style="font-size: 18px;font-weight: 700;color: #141F3B;margin-bottom: 10px">Hủy đơn hàng thất bại</p>
        <p style="font-size: 14px;font-weight: 400;color: #5D5B5B">Hủy đơn hàng thất bại <br> Vui lòng kiểm tra lại đơn hàng!</p>
        <a href="/gio-hang" >
          <button  type="button" class="btn btn-primary btn-lg btn-block" style="background: #FFFFFF;border-color: #FD799D;color: #FD799D;margin-bottom: 10px">Quản lý đơn hàng</button>
        </a>
        <a href="/">
          <button type="button" class="btn btn-secondary btn-lg btn-block" style="color:#FFFFFF;background: #FD799D;border-color: #FD799D">Về trang chủ Babyface</button>
        </a>
      </div>
    </div>
  </div>
</div>
