<div class="policy">
    <div class=WordSection1>

        <p class=MsoNormal><b><span style='font-size:16.0pt;line-height:107%;
        color:red'>CHÍNH SÁCH VÀ QUY ĐỊNH CHUNG</span></b></p>
        
        <p class=MsoNormal><b>1. Nguyên tắc chung</b></p>
        
        <p class=MsoNormal>Website babyface.beberia.me được vận hành bởi:</p>
        
        <p class=MsoListParagraphCxSpFirst style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>CÔNG TY TNHH BEBERIA VIỆT NAM</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Giấy chứng nhận đăng ký doanh nghiệp số 0109402765 do Sở Kế
        hoạch và Đầu tư thành phố Hà Nội cấp ngày 04/11/2020</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Địa chỉ: Số 167 phố Đồng Cam, Thị Trấn Liên Quan, Huyện Thạch Thất,
        Thành phố Hà Nội, Việt Nam</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Đại diện theo pháp luật: Park Dae Woong – chức danh: Tổng Giám
        đốc</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Số điện thoại: 0782333035      </p>
        
        <p class=MsoListParagraphCxSpLast style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Email: beberiavn@gmail.com</p>
        
        <p class=MsoNormal>Các thông tin đăng tải trên website phải đáp ứng đầy đủ các
        quy định của pháp luật có liên quan. Không nằm trong các loại thông tin mà Nhà
        nước cấm.</p>
        
        <p class=MsoNormal>Mọi hoạt động trên website babyface.beberia.me được thực hiện
        công khai, minh bạch và đảm bảo quyền lợi của người sử dụng</p>
        
        <p class=MsoNormal>Tất cả các nội dung trong quy định này phải tuân thủ theo hệ
        thống pháp luật hiện hành của Việt Nam. Thành viên khi tham gia vào website babyface.beberia.me
        phải đọc rõ các thỏa thuận sử dụng, chính sách bảo mật của website babyface.beberia.me
        cũng như phải tự tìm hiểu trách nhiệm pháp lý của mình đối với luật pháp hiện
        hành của Việt Nam và cam kết thực hiện đúng những nội dung trong các chính
        sách.</p>
        
        <p class=MsoNormal><b>2.  Quy định chung</b></p>
        
        <p class=MsoListParagraphCxSpFirst style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Chủ sở hữu: CÔNG TY TNHH BEBERIA VIỆT NAM</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Tên gọi chính của website: babyface.beberia.me</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Tên viết tắt của website: babyface.beberia.me</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Tên miền chính của website: babyface.beberia.me</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Tên miền phụ: babyface.beberia.me</p>
        
        <p class=MsoListParagraphCxSpLast style='margin-left:.75in;text-indent:-.25in'>-<span
        style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Định nghĩa chung:</p>
        
        <p class=MsoNormal style='text-indent:.5in'>Khách hàng: là thương nhân, tổ chức,
        cá nhân có nhu cầu mua hàng hóa của Công ty trên website babyface.beberia.me</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal><b>3. Hướng dẫn sử dụng website</b></p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>Nghiêm cấm sử dụng bất kỳ phần nào của trang web này với mục
        đích thương mại nếu không được Công ty cho phép bằng văn bản. Nếu vi phạm bất cứ
        điều nào trong đây, chúng tôi không chịu bất kỳ trách nhiệm nào, dù trực tiếp
        hay gián tiếp, đối với những thiệt hại hoặc mất mát gây ra do quý khách không
        tuân thủ quy định.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal><b>4. Thương hiệu và bản quyền</b></p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>Mọi quyền sở hữu trí tuệ (đã đăng ký hoặc chưa đăng ký), nội
        dung thông tin và tất cả các thiết kế, văn bản, đồ họa, phần mềm, hình ảnh,
        video, âm nhạc, âm thanh, biên dịch phần mềm, mã nguồn và phần mềm cơ bản đều
        là tài sản của Chúng tôi. Toàn bộ nội dung của trang web được bảo vệ bởi luật bản
        quyền của Việt Nam và các công ước quốc tế. Bản quyền đã được bảo lưu.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal><b>5. Quyền pháp lý</b></p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>Các điều kiện, điều khoản và nội dung của trang web này được
        điều chỉnh bởi luật pháp Việt Nam và Tòa án có thẩm quyền tại Việt Nam sẽ giải
        quyết bất kỳ tranh chấp nào phát sinh từ việc sử dụng trái phép trang web này.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal><b>6. CHÍNH SÁCH BẢO MẬT THÔNG TIN NGƯỜI DÙNG</b></p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>a.  Mục đích thu thập thông tin người dùng</p>
        
        <p class=MsoNormal>Việc thu thập dữ liệu của khách hàng để hỗ trợ, tư vấn thông
        tin về các dòng sản phẩm của chúng tôi đang cung cấp đến với khách hàng.</p>
        
        <p class=MsoNormal>Khách hàng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ các
        thông tin liên hệ và hộp thư điện tử của mình. Ngoài ra, khách hàng có trách
        nhiệm thông báo kịp thời cho chúng tôi  về những hành vi sử dụng trái phép, lạm
        dụng, vi phạm bảo mật, lưu giữ thông tin của bên thứ ba để có biện pháp giải
        quyết phù hợp.</p>
        
        <p class=MsoNormal>b.  Phạm vi sử dụng thông tin</p>
        
        <p class=MsoListParagraphCxSpFirst style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Hỗ trợ khách hàng khi mua sản phẩm của chúng tôi.</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Giải đáp thắc mắc khách hàng.</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Cung cấp cho khách hàng thông tin mới về sản phẩm</p>
        
        <p class=MsoListParagraphCxSpMiddle style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Thực hiện các bản khảo sát khách hàng.</p>
        
        <p class=MsoListParagraphCxSpLast style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Thực hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch
        vụ của chúng tôi.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>c. Thời gian lưu trữ thông tin</p>
        
        <p class=MsoNormal>Dữ liệu của khách hàng sẽ được lưu trữ cho đến khi có yêu cầu
        hủy bỏ hoặc tự khách hàng đăng nhập và thực hiện hủy bỏ. Còn lại trong mọi trường
        hợp thông tin khách hàng sẽ được bảo mật trên hệ thống máy chủ chúng tôi.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>d. Những người hoặc tổ chức có thể được tiếp cận với thông
        tin  </p>
        
        <p class=MsoNormal>Chúng tôi cam kết sẽ không chia sẻ thông tin của khách hàng
        cho bất kỳ một công ty nào khác theo chính sách của công ty chúng tôi. Chúng
        tôi có thể tiết lộ hoặc cung cấp thông tin của Quý khách trong các trường hợp
        thật sự cần thiết như sau:</p>
        
        <p class=MsoListParagraphCxSpFirst style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Khi có yêu cầu của các cơ quan pháp luật.</p>
        
        <p class=MsoListParagraphCxSpLast style='margin-left:.75in;text-indent:-.25in'><span
        style='font-family:"Sitka Subheading Semibold"'>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span>Chia sẻ thông tin khách hàng với đối tác chạy quảng cáo như
        Google ví dụ như tiếp thị lại khách hàng dựa theo hành vi của khách hàng.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>e. Địa chỉ của đơn vị thu thập và quản lý thông tin:</p>
        
        <p class=MsoNormal>CÔNG TY TNHH BEBERIA VIỆT NAM</p>
        
        <p class=MsoNormal>Địa chỉ: Số 167 phố Đồng Cam, Thị Trấn Liên Quan, Huyện Thạch
        Thất, Thành phố Hà Nội, Việt Nam</p>
        
        <p class=MsoNormal><b>7. Mục đích và phạm vi thu thập</b></p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>Việc thu thập dữ liệu chủ yếu trên website babyface.beberia.me
        bao gồm: Email, điện thoại, tên khách hàng, địa chỉ khách hàng. Đây là các
        thông tin mà babyface.beberia.me yêu cầu khách hàng cung cấp bắt buộc khi đăng
        ký mua sản phẩm và để babyface.beberia.me liên hệ xác nhận khi khách hàng
        đăng ký mua sản phẩm trên website nhằm đảm bảo quyền lợi cho người tiêu dùng.
        Trong quá trình giao dịch thanh toán tại Website babyface.beberia.me, Chúng tôi
        chỉ lưu giữ thông tin chi tiết về đơn hàng đã thanh toán của khách hàng. Chúng
        tôi cũng sẽ sử dụng cả thông tin nhận diện cá nhân của thành viên để gia tăng
        khả năng đáp ứng của Chúng tôi về nhu cầu của khách hàng cũng như về phát
        triển những chức năng, tính năng và các sản phẩm mới theo những xu hướng và sở
        thích đang diễn tiến.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal><b>8. Những quy định khác</b></p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <p class=MsoNormal>Tất cả các Điều Khoản và Điều Kiện (và tất cả nghĩa vụ phát
        sinh ngoài Điều khoản và Điều kiện này hoặc có liên quan) sẽ bị chi phối và được
        hiểu theo luật pháp Việt Nam. Chúng tôi có quyền sửa đổi các Điều khoản và Điều
        kiện này vào bất kỳ thời điểm nào và các sửa đổi đó sẽ có hiệu lực ngay tại thời
        điểm được được đăng tải tại babyface.beberia.me.</p>
        
        <p class=MsoNormal>Xin quý khách lưu ý chỉ đăng ký sử dụng dịch vụ khi chấp nhận
        và hiểu rõ những quy định trên và các chính sách đi kèm.</p>
        
        <p class=MsoNormal>Nếu cần hỗ trợ thêm thông tin, quý khách vui lòng liên hệ: </p>
        
        <p class=MsoNormal>Hotline: 0782333035                 </p>
        
        <p class=MsoNormal>Email: beberiavn@gmail.com</p>
        
        <p class=MsoNormal>Trân trọng.</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        </div>
</div>