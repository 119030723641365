import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Frame } from 'src/app/Model/Frame';
import { Item } from 'src/app/Model/Item';
import { BabyfaceService } from 'src/app/api/babyface.service';
import { Review } from 'src/app/Model/Review';

@Component({
  selector: 'app-babyface',
  templateUrl: './babyface.component.html',
  styleUrls: ['./babyface.component.css'],
})
export class BabyfaceComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  listFrame: Frame[] = [];
  item: Item[] = [];
  frameDefault = '';
  i = 0;
  listReview: Review[] = [];
  isPlay = 0;

  scrollId = 0;
  dataSlide = 0;

  auth2: any;

  constructor(private babyfaceService: BabyfaceService) {}

  ngOnInit(): void {
    this.getFrame();
    this.getItem();
    this.getReview();
  }
  scrollEvent = (event: any): void => {
    if (this.isPlay == 1) {
      const n = event.srcElement.scrollingElement.scrollTop;
      if (n > 1000 || n < 100) {
        this.pause();
      }
    }
  };
  /**
   * Get Frame
   */
  getFrame() {
    this.babyfaceService.getFrame().subscribe((resp: any) => {
      if (resp.status === 200) {
        this.listFrame = resp.data;
        this.frameDefault = this.listFrame[0].link_sample;
      }
    });
  }
  /**
   * Get item
   */
  getItem() {
    this.babyfaceService.getItem().subscribe((res: any) => {
      this.item = res.data.datas;
    });
  }
  /**
   * get review
   */
  getReview() {
    this.babyfaceService.getReview().subscribe((resp: any) => {
      if (resp.status === 200) {
        this.listReview = resp.data.datas;
      }
    });
  }
  /**
   * scroll
   */
  scroll(el: HTMLElement, index) {
    this.scrollId = index;
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  /**
   * Set Data Silde
   */
  setDataSilde(e) {
    this.dataSlide = e;
  }
  play() {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.videoplayer.nativeElement.play();
    this.isPlay = 1;
  }
  pause() {
    if (this.isPlay == 1) {
      this.videoplayer.nativeElement.pause();
      this.isPlay = 0;
    } else if (this.isPlay == 0) {
      window.addEventListener('scroll', this.scrollEvent, true);
      this.videoplayer.nativeElement.play();
      this.isPlay = 1;
    }
  }
  formatPrice(e) {
    return Intl.NumberFormat().format(e * 100);
  }
}
