import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BabyfaceService} from '../../api/babyface.service';

import {LocationService} from '../../api/location.service';
import {City} from '../../Model/City';

import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  listCity: City[] = [];

  formRegister: FormGroup;
  formLogin : FormGroup;
  validateFileChild = false;
  // tslint:disable-next-line:variable-name
  check_policy= false;
  // tslint:disable-next-line:variable-name
  age_range_checked = 0;
  // tslint:disable-next-line:variable-name
  res_error = [];
  submited = false;
  focused:boolean = false
  @ViewChild('displayName',{static: false}) displayName : ElementRef;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private babyfaceService: BabyfaceService,
    private locationService: LocationService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.formRegister = this.formBuilder.group({
      display_name: ['', [Validators.required, Validators.minLength(6)]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      city_code: ['', Validators.required],
      age_range: ['', Validators.required],
      provider_id: ['',Validators.required],
      birthday_baby: [null],
      birthday_preg: [null]
    });
    this.formLogin = this.formBuilder.group({
      provider_id: [''],
    });
    this.getCity();
    if (!localStorage.getItem('idSocial')){
      this.router.navigate(['dang-nhap']);
    }
    this.formRegister.controls.provider_id.setValue(localStorage.getItem('idSocial'));
  }

  get f() { return this.formRegister.controls; }
  scroll(el: HTMLElement) {
    if(this.focused){
      el.scrollIntoView();
    }
  }
  postRegiter(): void
  { 
    this.submited = true;   
    if (this.formRegister.valid && this.check_policy == true  )
    {
      this.babyfaceService.postRegiterSocial(this.formRegister.value).subscribe((resp) => {        
        if (resp.status === 200)
        {
          let user = {
            name: resp.data.display_name,
            avt: resp.data.avatar,
            idx: resp.data.id,
          }
          localStorage.setItem('user',JSON.stringify(user));
          this.formLogin.controls.provider_id.setValue(localStorage.getItem('idSocial'));
          this.babyfaceService.postLogin(this.formLogin.value).subscribe((res) => {
            if (res.status === 200)
            {
              let token ={
                value : res.data.token,
                expiry: res.data.exp
              }
              localStorage.setItem('token',JSON.stringify(token));
              window.location.href = '/';
            }
          });
          localStorage.removeItem('idSocial');
        }
        // tslint:disable-next-line:triple-equals
        else if (resp.status == 414){
          this.res_error = resp.message;
          this.focused = true;
        } 
        else {
          alert('Đăng ký thất bại!');
          window.location.href = '/';
        }
      });
      return;
    }else this.focused = true;
  }
  getCity(): void {
    this.locationService.getList().subscribe((reps) => {
      if (reps.status === 200)
      {
        this.listCity = reps.data.datas;
      }
    });
  }
  // tslint:disable-next-line:typedef
  set_age_range(e){
    this.age_range_checked = e;
    this.formRegister.controls.age_range.setValue(e);
  }
}
