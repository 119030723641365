import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) {}

  public isAuthenticated(): boolean {
    let token = localStorage.getItem('token');
    // Check whether the token is expired and return
    // true or false
    const item = JSON.parse(token);
    if (token != null){
      if (Math.floor(new Date().getTime() / 1000) > item.expiry){        
        token = null;
        localStorage.clear();
      }
    }
    return !this.jwtHelper.isTokenExpired(token);
  }
}
